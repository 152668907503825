import React from "react";
import { Product } from "./table";
import { createColumnHelper } from "@tanstack/react-table";

import { RowActions, VisibilityStatus } from "./cells";
import { LuImage } from "react-icons/lu";

const columnHelper = createColumnHelper<Product>();

const columns = () => [
  columnHelper.accessor("name", {
    header: "Product",
    minSize: 200,
    cell: ({ row, getValue }) => {
      return (
        <p className="flex flex-row items-center w-full gap-3 font-medium text-gray-800">
          {row.original.image_url ? (
            <img
              className="object-cover w-20 h-12 rounded"
              src={row.original.image_url}
              alt={row.original.name}
            />
          ) : (
            <span className="w-20 h-12 bg-gray-100 rounded grid items-center justify-center *:text-gray-400 *:size-6">
              <LuImage />
            </span>
          )}
          <span className="truncate max-w-64">{getValue()}</span>
        </p>
      );
    },
  }),
  columnHelper.accessor("visibility", {
    header: "Status",
    minSize: 200,
    cell: ({ row, getValue }) => {
      return <VisibilityStatus value={getValue()} />;
    },
  }),
  columnHelper.display({
    id: "actions",
    maxSize: 32,
    cell: ({ row }) => {
      return <RowActions row={row} />;
    },
  }),
];

export default columns;
