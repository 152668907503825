import { LuMoreVertical, LuPencil, LuTrash2, LuVariable } from "react-icons/lu";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/components/dropdown";

import { cn } from "~/utils/tailwind";

import { CustomReport } from "../../types";
import { EditDataDialog } from "../../edit/query/editDialog";
import { useDialog } from "~/hooks/useDialog";
import { DeleteDataDialog } from "../../edit/query/deleteDialog";
import { useReportManagement } from "../hooks/useReportManagement";
import { VariablesDialog } from "../../edit/query/variablesDialog";

export const MoreOptions = ({ report }: { report: CustomReport }) => {
  const editDialog = useDialog();
  const deleteDialog = useDialog();
  const variablesDialog = useDialog();
  const { disabledQueries } = useReportManagement();
  const isDisabled = disabledQueries[report.id];

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger className="btn h-full p-2" disabled={isDisabled}>
        <LuMoreVertical className="size-4" />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem className="flex gap-2" onClick={editDialog.trigger}>
          <LuPencil className="size-4" />
          Edit details
        </DropdownMenuItem>
        <DropdownMenuItem
          className={cn("flex gap-2", !report.can_view_variables && "hidden")}
          onClick={variablesDialog.trigger}
        >
          <LuVariable className="size-4" />
          Variables
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="flex gap-2"
          onClick={deleteDialog.trigger}
          disabled={!report.parent_id}
        >
          <LuTrash2 className="size-4" />
          Delete data
        </DropdownMenuItem>
      </DropdownMenuContent>

      <EditDataDialog
        defaultValues={{
          store_id: report.store_id,
          parent_id: report.parent_id,
          report_id: report.id,
          google_sheet_tab_name: report.google_sheet_tab,
          query: report.query ?? "",
        }}
        openDialog={editDialog.props.open}
        onOpenChange={editDialog.dismiss}
      />
      <VariablesDialog
        values={{
          report_id: report.id,
          variables: report.variables,
        }}
        openDialog={variablesDialog.props.open}
        onOpenChange={variablesDialog.dismiss}
      />
      <DeleteDataDialog
        values={{
          store_id: report.store_id,
          report_id: report.id,
          query_name: report.google_sheet_tab,
        }}
        openDialog={deleteDialog.props.open}
        onOpenChange={deleteDialog.dismiss}
      />
    </DropdownMenu>
  );
};
