import { application } from "./application"

import { registerControllers } from "stimulus-vite-helpers";
import plugin, { TurboMount } from "turbo-mount/react";
import { registerComponents } from "turbo-mount/registerComponents/vite";

const controllers = import.meta.glob("./**/*_controller.{js,jsx}", { eager: true });
const components = import.meta.glob("../components/**/*.tsx", { eager: true });

registerControllers(application, controllers);

const turboMount = new TurboMount();
registerComponents({ plugin, turboMount, components });
