import { Controller } from "stimulus";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import Litepicker from "litepicker";
import "litepicker/dist/plugins/keyboardnav";
import "litepicker/dist/plugins/ranges";
import "litepicker/dist/plugins/mobilefriendly";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export default class extends Controller {
  static values = {
    timezone: String,
  };

  connect() {
    if (!this.timezoneValue) {
      throw new Error(
        `Timezone value for data-controller="datepicker" must be set`
      );
    }

    const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;

    const today = new Date(
      new Date().toLocaleString("en-US", { timeZone: this.timezoneValue })
    );
    const startOfDay = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    const picker = new Litepicker({
      element: this.element,
      plugins: ["ranges", "keyboardnav", "mobilefriendly"],
      numberOfMonths: 2,
      autoApply: false,
      numberOfColumns: 2,
      showTooltip: true,
      resetButton: true,
      minDate: new Date(2022, 4 - 1, 13), // April 13, 2022
      maxDate: new Date(),
      format: "YYYY/MM/DD",
      resetToday: false,
      ranges: {
        customRanges: {
          Today: [startOfDay, startOfDay],
          Yesterday: [
            new Date(startOfDay.getTime() - MILLISECONDS_IN_DAY),
            new Date(startOfDay.getTime() - MILLISECONDS_IN_DAY),
          ],
          "Last 7 days": [
            new Date(startOfDay.getTime() - 6 * MILLISECONDS_IN_DAY),
            today,
          ],
          "Last 30 days": [
            new Date(startOfDay.getTime() - 29 * MILLISECONDS_IN_DAY),
            today,
          ],
          "This month": [
            new Date(today.getFullYear(), today.getMonth(), 1),
            today,
          ],
          "Last month": [
            new Date(today.getFullYear(), today.getMonth() - 1, 1),
            new Date(today.getFullYear(), today.getMonth(), 0),
          ],
        },
      },
      setup: (picker) => {
        picker.on("button:apply", (date1, date2) => {
          const params = new URLSearchParams(window.location.search);

          params.set(
            "start_date",
            dayjs(date1.dateInstance).format("YYYY-MM-DD")
          );
          params.set(
            "end_date",
            dayjs(date2.dateInstance).format("YYYY-MM-DD")
          );

          const url = `${window.location.pathname}?${params}`;
          window.location.replace(url);
        });
        picker.on("render:day", (day, date) => {
          // Reset current day formatting
          day.classList.remove("is-today");

          // Style our timezone adjusted `today` date as today
          if (date.toDateString() === today.toDateString()) {
            day.classList.add("is-today");
          }
        });
        picker.on("render:footer", (footer, date) => {
          const timezoneMessage = document.createElement("p");
          timezoneMessage.textContent = `Timezone: ${dayjs()
            .tz(this.timezoneValue)
            .format("zzz (z)")}`;
          timezoneMessage.classList.add("pl-2.5");

          // Group the buttons to be moved to the right
          const wrappedButtons = document.createElement("div");
          Array.from(footer.children).forEach((element) => {
            wrappedButtons.appendChild(element);
          });

          footer.innerHTML = "";
          footer.appendChild(timezoneMessage);
          footer.appendChild(wrappedButtons);
          footer.classList.add(
            "flex",
            "flex-row",
            "items-center",
            "w-full",
            "justify-between"
          );
        });
      },
    });

    this.element.setAttribute('readonly', true);

    const urlParams = new URLSearchParams(window.location.search);
    const startDateParam = urlParams.get("start_date");
    const endDateParam = urlParams.get("end_date");

    let startDate = dayjs(startDateParam, "YYYY/MM/DD").tz(this.timezoneValue);
    let endDate = dayjs(endDateParam, "YYYY/MM/DD").tz(this.timezoneValue);

    if (
      startDateParam &&
      endDateParam &&
      startDate.isValid() &&
      endDate.isValid()
    ) {
      startDate = startDate.toDate();
      endDate = endDate.toDate();
    } else {
      endDate = dayjs().tz(this.timezoneValue).format("YYYY-MM-DD");
      startDate = dayjs(endDate).tz(this.timezoneValue).subtract(14, "day");
    }

    picker.setDateRange(startDate, endDate);
  }
}
