import React, { useState } from "react";
import { LuExternalLink, LuLoader } from "react-icons/lu";

import { CustomReport } from "../types";
import { Switch } from "~/components/switch";

import { editReport, getGoogleSheetUrl } from "../data";
import { GoogleSheetSetupInfo } from "./googleSheetSetupInfo";
import FormErrors from "./formErrors";

export const CustomReportEdit = ({ report }: { report: CustomReport }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [formState, setFormState] = useState({
    name: report?.name || "",
    description: report?.description || "",
    enabled: report?.enabled || false,
    google_sheet_url: report?.google_sheet_url || "",
    errors: report?.errors || undefined,
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSwitch = (e: boolean) => {
    setFormState((prevState) => ({
      ...prevState,
      enabled: e.valueOf(),
    }));
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    if (confirm("Are you sure you want to cancel?") == true) {
      history.back();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsUpdating(true);

    const formData = new FormData();
    formData.append("[custom_report][name]", formState.name);
    formData.append("[custom_report][description]", formState.description);
    formData.append(
      "[custom_report][google_sheet_url]",
      formState.google_sheet_url
    );

    // Prevent a user from enabling a report without a Google Sheet URL
    if (formState.enabled === true && formState.google_sheet_url.length === 0) {
      setFormState((prevState) => ({
        ...prevState,
        enabled: false,
      }));
      formData.append("[custom_report][enabled]", "false");
    } else {
      formData.append("[custom_report][enabled]", formState.enabled.toString());
    }

    const response = await editReport({
      storeId: report.store_id,
      reportId: report.id,
      formData: formData,
    });

    setIsUpdating(false);

    if (response.ok) {
      window.location.href = `/stores/${report.store_id}/custom_reports/${report.id}`;
    } else {
      response.json().then((data) => {
        setFormState((prevState) => ({
          ...prevState,
          errors: data,
        }));
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="relative">
      <FormErrors errors={formState.errors} />

      <div className="absolute right-0 flex items-center gap-2 -top-10">
        <Switch
          name="enabled"
          defaultChecked={report.enabled}
          disabled={report.status === "RUNNING"}
          onCheckedChange={handleSwitch}
        />
      </div>

      <div>
        <label htmlFor="name" className="field-label">
          Name
        </label>
        <input
          type="text"
          name="name"
          id="name"
          onChange={handleInputChange}
          defaultValue={report.name}
          className="w-full field-input"
          data-1p-ignore="true"
          required
        />
      </div>

      <div className="mt-4">
        <label htmlFor="description" className="field-label">
          Description
        </label>
        <textarea
          name="description"
          id="description"
          onChange={handleInputChange}
          defaultValue={report.description}
          className="field-input max-h-32 min-h-[66px] w-full"
          data-1p-ignore="true"
          required
        />
      </div>

      <div className="mt-4">
        <label htmlFor="service_account_address" className="field-label">
          Google service account address
        </label>
        <p className="w-full px-3 py-2 leading-6 border border-gray-300 rounded min-h-[42px]">
          {report.service_account_address}
        </p>
      </div>

      <GoogleSheetSetupInfo />

      <div className="mt-4">
        <label htmlFor="google_sheet_url" className="field-label">
          Google Sheet URL
        </label>
        <p className="flex flex-row gap-2">
          <input
            type="text"
            name="google_sheet_url"
            id="google_sheet_url"
            onChange={handleInputChange}
            defaultValue={report.google_sheet_url}
            className="flex-1 w-auto field-input"
            data-1p-ignore="true"
          />
          {report?.google_sheet_url?.length ?? 0 > 0 ? (
            <a
              href={getGoogleSheetUrl(report.google_sheet_url)}
              className="flex flex-row items-center gap-2 btn w-fit"
              target="_blank"
            >
              <LuExternalLink className="size-4" /> View Report
            </a>
          ) : null}
        </p>
      </div>

      <div className="flex flex-row items-center justify-end gap-3 mt-12">
        <button
          onClick={handleCancel}
          className="px-5 ml-2 btn-secondary w-fit"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="flex items-center gap-2 px-5 btn-primary w-fit"
          disabled={isUpdating}
        >
          {isUpdating ? <LuLoader className="animate-spin" /> : null}
          {isUpdating ? "Updating..." : "Update Custom Report"}
        </button>
      </div>
    </form>
  );
};
