import React from "react";
import { LuEyeOff, LuMoreVertical, LuToggleLeft } from "react-icons/lu";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from "~/components/dropdown";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from "~/components/tooltip";
import { updateProductVisibility } from "./data";
import { getStoreId } from "../utils";
import { Product } from "./table";
import { Row } from "@tanstack/react-table";

export const VisibilityStatus = ({
  value
}: {
  value: Product["visibility"];
}) => {
  if (value == "hidden") {
    return (
      <Tooltip>
        <TooltipTrigger>
          <span className="flex items-center gap-2 px-2 py-1 text-red-700 bg-red-100 rounded">
            <LuEyeOff className="size-4" />
            <span className="text-sm font-medium">Hidden</span>
          </span>
        </TooltipTrigger>
        <TooltipContent>
          <p className="text-sm text-red-700">
            This product will not be shown in this content block.
          </p>
        </TooltipContent>
      </Tooltip>
    );
  }

  return null;
};

export const RowActions = ({ row }: { row: Row<Product> }) => {
  const { visibility } = row.original;
  const storeId = getStoreId() ?? "";

  const handleVisibilityChange = () => {
    const newValue = visibility == "hidden" ? "default" : "hidden";
    updateProductVisibility(storeId, newValue);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="p-2">
        <LuMoreVertical className="size-4" />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="min-w-52">
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="flex items-center gap-2 hover:bg-gray-200"
          onClick={handleVisibilityChange}
        >
          <LuToggleLeft className="size-4" />
          Toggle visibility
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
