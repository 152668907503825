import { LuInfo } from "react-icons/lu";

export const GoogleSheetSetupInfo = () => {
  return (
    <div className="flex flex-col justify-start gap-6 px-6 pt-6 pb-8 mt-5 overflow-hidden bg-white border border-blue-400 rounded">
      <div className="flex justify-start items-center gap-2.5">
        <LuInfo className="text-blue-500 size-5" />
        <p className="text-base font-medium text-blue-600">
          How to set up your Google Sheet
        </p>
      </div>

      <ol className="flex flex-col justify-start gap-5 pl-5 text-sm text-gray-800 list-decimal">
        <li>
          <p className="font-medium">Copy account address</p>
          <p className="text-gray-600">
            Begin by copying the Google service account address above.
          </p>
        </li>
        <li>
          <p className="font-medium">Create sheet</p>
          <p className="text-gray-600">Generate a new Google Sheet.</p>
        </li>
        <li>
          <p className="font-medium">Ensure Google Sheet tab exists</p>
          <p className="text-gray-600">
            Check that your Google Sheet has a tab with the same name provided
            under 'Google Sheet tab name'. Adjust this in your Google Sheet if
            not.
          </p>
        </li>
        <li>
          <p className="font-medium">Enable data sync</p>
          <p className="text-gray-600">
            Share the Google Sheet with the copied service account address by
            adding it as an Editor.
          </p>
        </li>
        <li>
          <p className="font-medium">Connect to Solve</p>
          <p className="text-gray-600">
            Copy the URL of the Google Sheet and paste it into the 'Google Sheet
            URL' input field provided below.
          </p>
        </li>
      </ol>
    </div>
  );
};

export const GoogleSheetTabSetupInfo = () => {
  return (
    <div className="flex flex-col justify-start gap-6 px-6 pt-6 pb-8 overflow-hidden bg-white border border-blue-400 rounded">
      <div className="flex justify-start items-center gap-2.5">
        <LuInfo className="text-blue-500 size-5" />
        <p className="text-base font-medium text-blue-600">
          How to set up Google Sheet tabs
        </p>
      </div>

      <ol className="flex flex-col justify-start gap-5 pl-5 text-sm text-gray-800 list-decimal">
        <li>
          <p className="font-medium">Create a new tab in your Google Sheet</p>
          <p className="text-gray-600">
            Name this new tab to reflect the query or data it will store.
          </p>
        </li>
        <li>
          <p className="font-medium">Configure your tab</p>
          <p className="text-gray-600">
            Add the name of your new tab into the 'Google Sheet tab name' input
            filed provided below. Ensure the two names match.
          </p>
        </li>
      </ol>
    </div>
  );
};
