import { Controller } from "@hotwired/stimulus";
import { createRoot } from "react-dom/client";

import { startOfDay, endOfDay } from "date-fns";
import { toZonedTime } from "date-fns-tz";

import Calendar from "../../components/automations/scheduling/calendar";

// Connects to data-controller="automations--scheduling"
export default class extends Controller {
  static targets = ["calendar"];

  static values = {
    url: String,
    initialValues: Array,
    timeZone: String,
  };

  connect() {
    this.validateInputs();

    const events = this.formatData(this.initialValuesValue, this.timeZoneValue);

    createRoot(this.calendarTarget).render(<Calendar events={events} />);
  }

  validateInputs() {
    if (!this.calendarTarget) {
      throw new Error(
        `Calendar target for data-controller="automations--scheduling" must be set`
      );
    }

    if (!this.initialValuesValue) {
      throw new Error(
        `initialValues value for data-controller="automations--scheduling" must be set`
      );
    }

    if (!this.timeZoneValue) {
      throw new Error(
        `timeZone value for data-controller="automations--scheduling" must be set`
      );
    }
  }

  formatData(data, timeZone) {
    return data.map((schedule) => {
      const startDate = startOfDay(toZonedTime(schedule.start, timeZone));
      const endDate = endOfDay(toZonedTime(schedule.end, timeZone));

      return {
        ...schedule,
        start: startDate,
        end: endDate,
      };
    });
  }
}
