import React from "react";
import clsx from "clsx";
import {
  LuCheckCircle2,
  LuCircleEllipsis,
  LuRefreshCw,
  LuXCircle,
} from "react-icons/lu";

import { AudienceStatus } from "./types";

interface StatusConfig {
  icon: React.ElementType;
  text: string;
  textColor: string;
  borderColor: string;
}

const statusConfig: Record<AudienceStatus, StatusConfig> = {
  COMPLETED: {
    icon: LuCheckCircle2,
    text: "Ready",
    textColor: "text-green-600",
    borderColor: "border-green-600",
  },
  RUNNING: {
    icon: LuRefreshCw,
    text: "Running",
    textColor: "text-yellow-600",
    borderColor: "border-yellow-600",
  },
  FAILED: {
    icon: LuXCircle,
    text: "Failed",
    textColor: "text-red-600",
    borderColor: "border-red-600",
  },
};

const StatusIcon = ({ status }: { status: AudienceStatus }) => {
  const Icon = statusConfig[status]?.icon || LuCircleEllipsis;
  return (
    <Icon className={clsx("w-3 h-3", status === "RUNNING" && "animate-spin")} />
  );
};

const StatusLabel = ({ status }: { status: AudienceStatus }) => {
  const { text, textColor, borderColor } = statusConfig[status] || {};
  const statusClasses =
    "px-2 flex gap-1.5 items-center text-xs border rounded w-fit py-0.5";

  return (
    <span className={clsx(statusClasses, textColor, borderColor)}>
      <StatusIcon status={status} />
      {text}
    </span>
  );
};

export default StatusLabel;
