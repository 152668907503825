import { useState, useCallback, useEffect } from "react";
import { CustomReport } from "../../types";
import { editReport } from "../../data";

export const useFormState = (report: CustomReport) => {
  const [formState, setFormState] = useState({
    enabled: report.enabled,
    errors: report.errors,
  });

  useEffect(() => {
    setFormState({
      enabled: report.enabled,
      errors: report.errors,
    });
  }, [report]);

  const handleSwitch = useCallback(
    async (enabled: boolean) => {
      const formData = new FormData();
      formData.append("[custom_report][enabled]", enabled.toString());

      const response = await editReport({
        storeId: report.store_id,
        reportId: report.id,
        formData,
      });

      if (!response.ok) {
        const data = await response.json();
        setFormState((prevState) => ({
          ...prevState,
          enabled: report.enabled,
          errors: data,
        }));
      }
    },
    [report.store_id, report.id, report.enabled]
  );

  return { formState, handleSwitch };
};
