import { Controller } from "@hotwired/stimulus";
import { createRoot } from "react-dom/client";

import AudienceCard from "../components/audiences/audienceCard";
import AudienceDetails from "../components/audiences/audienceDetails";

// Connects to data-controller="audience"
export default class extends Controller {
  static targets = ["audience"];

  static values = {
    audience: Object,
    type: String,
  };

  connect() {
    this.validateInputs();

    if (this.typeValue === "card") {
      createRoot(this.audienceTarget).render(
        <AudienceCard audience={this.audienceValue} />
      );
    } else if (this.typeValue === "details") {
      createRoot(this.audienceTarget).render(
        <AudienceDetails audience={this.audienceValue} />
      );
    }
  }

  validateInputs() {
    if (!this.audienceTarget) {
      throw new Error(
        `Audience target for data-controller="audience" must be set`
      );
    }

    if (!this.audienceValue) {
      throw new Error(
        `Audience value for data-controller="audience" must be set`
      );
    }

    if (
      !this.typeValue ||
      (this.typeValue !== "card" && this.typeValue !== "details")
    ) {
      throw new Error(
        `Type value for data-controller="audience" must be set to either "card" or "details"`
      );
    }
  }
}
