import React, { useEffect, useState } from "react";
import { LuSettings, LuUser } from "react-icons/lu";
import clsx from "clsx";

import { pluralize } from "../../utils/string-formatters";
import { cn } from "../../utils/tailwind";
import consumer from "../../channels/consumer";

import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
} from "../tooltip";
import { Switch } from "../switch";
import TimeAgo from "../time-ago";
import DownloadButton from "./downloadButton";
import RefreshButton from "./refreshButton";
import StatusLabel from "./label";

import {
  AUDIENCE_POLLING_INTERVAL,
  downloadCsv,
  fetchAudience,
  refreshAudience,
  toggleAudience,
} from "./data";
import { Audience } from "./types";

const AudienceListItem = ({ audience }: { audience: Audience }) => {
  const [activeAudience, setActiveAudience] = useState<Audience>(audience);
  const [isDownloadable, setIsDownloadable] = useState(
    audience.status !== "RUNNING" && audience.size !== 0
  );
  const [isRefreshable, setIsRefreshable] = useState(
    audience.status !== "RUNNING"
  );

  const refreshSyncStatus = async () => {
    try {
      // Sync status will be refreshed by GET call to audience endpoint
      await fetchAudience(activeAudience.store_id, activeAudience.id);
    } catch (error) {
      console.error("Error fetching audience:", error);
    }
  };

  const downloadAudience = async (store_id: string, audience_id: string) => {
    setIsDownloadable(false);
    await downloadCsv(store_id, audience_id);
    setIsDownloadable(true);
  };

  // Refresh audience sync status on initial load for all enabled audiences
  useEffect(() => {
    if (activeAudience.enabled) {
      refreshSyncStatus();
    }
  }, []); // Empty dependency array to run this effect once on initial mount;

  useEffect(() => {
    const subscription = consumer.subscriptions.create(
      { channel: "AudienceChannel", audience_id: audience.id },
      {
        received(data: Audience) {
          const { status, size } = data;
          setActiveAudience({ ...audience, ...data });

          if (status) {
            setIsDownloadable(status !== "RUNNING" && size !== 0);
            setIsRefreshable(status !== "RUNNING");
          }
        },
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [audience]);

  useEffect(() => {
    // We only need to poll when an audience is running and enabled (ie. syncing via Census)
    let interval: number;
    if (activeAudience.status === "RUNNING" && activeAudience.enabled) {
      interval = setInterval(refreshSyncStatus, AUDIENCE_POLLING_INTERVAL);
    }

    return () => {
      clearInterval(interval);
    };
  }, [activeAudience.status, activeAudience.enabled]);

  const profiles = pluralize(activeAudience.size, "profile");

  return (
    <TooltipProvider>
      <div className="flex flex-col justify-between w-full bg-white border rounded @container">
        <div className="flex flex-col justify-between gap-5 p-4">
          <div className="flex flex-col justify-between gap-1 items-top">
            <p className="flex flex-row gap-1.5 justify-between overflow-hidden">
              <a
                href={`audiences/${audience.id}`}
                title={audience?.name}
                className={cn(
                  "font-medium leading-tight text-gray-800 truncate",
                  audience.is_klaviyo_setup &&
                    "max-w-[80%] @[26rem]:max-w-80 @md:max-w-[85%]"
                )}
              >
                {audience?.name}
              </a>
              {audience.is_klaviyo_setup ? (
                <Switch
                  defaultChecked={activeAudience.enabled}
                  disabled={!isRefreshable}
                  onCheckedChange={() =>
                    toggleAudience(
                      activeAudience.enabled,
                      activeAudience.store_id,
                      activeAudience.id
                    )
                  }
                />
              ) : null}
            </p>
            <p className="h-8 text-xs text-gray-500 line-clamp-2">
              {audience?.description}
            </p>
          </div>

          <div className="flex flex-row items-center justify-between pt-7">
            <Tooltip>
              <TooltipTrigger
                className={cn(
                  activeAudience.status !== "FAILED" && "cursor-default"
                )}
              >
                <StatusLabel status={activeAudience.status} />
              </TooltipTrigger>
              {activeAudience.status === "FAILED" ? (
                <TooltipContent
                  className={cn(
                    "flex flex-col w-full text-red-700 border-red-200 rounded shadow-md",
                    activeAudience.status !== "FAILED" && "hidden"
                  )}
                >
                  {activeAudience.failures}
                </TooltipContent>
              ) : null}
            </Tooltip>

            <Tooltip>
              <TooltipTrigger asChild>
                <p className="text-xs text-gray-500">
                  <span>Last refreshed</span>{" "}
                  <TimeAgo timestamp={activeAudience.last_refreshed_at} />
                </p>
              </TooltipTrigger>
              <TooltipContent>
                {activeAudience.last_refreshed_at_formatted}
              </TooltipContent>
            </Tooltip>
          </div>
        </div>

        <div className="flex flex-row justify-between p-4 border-t bg-gray-50">
          <div className="flex flex-row items-center gap-3">
            <span className="block p-2 mx-auto bg-white rounded w-fit">
              <LuUser className="w-5 h-auto" />
            </span>
            <p className="flex flex-col">
              <span
                className={clsx(
                  "text-sm text-gray-800",
                  activeAudience?.size === 0 && "text-red-500"
                )}
              >
                {profiles}
              </span>
            </p>
          </div>

          <div className="flex flex-row gap-2">
            <DownloadButton
              audience={activeAudience}
              onClick={() => downloadAudience(audience.store_id, audience.id)}
              disabled={!isDownloadable}
            />

            <RefreshButton
              audience={activeAudience}
              onClick={() => refreshAudience(audience.store_id, audience.id)}
              disabled={!isRefreshable}
            />

            {audience.can_edit ? (
              <a
                href={`audiences/${audience.id}/edit`}
                className="p-2 btn btn-sm"
              >
                <LuSettings className="w-4 h-auto" />
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </TooltipProvider>
  );
};

export default AudienceListItem;
