import { CustomReport } from "./types";
import { VariableType } from "./view/queries/controls/schema";

const headers = {
  "X-Csrf-Token":
    document.querySelector("[name='csrf-token']")?.getAttribute("content") ??
    "",
};

/**
 * Refresh a custom report's data.
 *
 * @param {string} storeId The unique identifier for the store to which the report belongs.
 * @param {string} reportId The unique identifier for the custom report being refreshed.
 *
 * @example
 * // To refresh the report for a store with ID '123' and report ID 'abc':
 * refreshReport('123', 'abc');
 */
export const refreshReport = (storeId: string, reportId: string) => {
  fetch(`/stores/${storeId}/custom_reports/${reportId}/run_manual_sync`, {
    method: "POST",
    headers,
  }).catch((error) => console.error("Refresh Report error:", error));
};

/**
 *  How often we poll for changes to an audience in ms.
 *
 *  Currently: 1 minute
 */
export const CUSTOM_REPORT_POLLING_INTERVAL = 1 * 60 * 1000;

/**
 * Format a Google Sheet URL as an absolute path.
 *
 * @param {string} url The URL to be formatted.
 *
 * @example
 * // To format a Google Sheet URL as an absolute path
 * getGoogleSheetUrl('google.com') // returns 'https://google.com'
 */
export const getGoogleSheetUrl = (url?: string) => {
  if (!url) return "#";

  return url.startsWith("http") ? url : `https://${url}`;
};

/**
 * Asynchronously sends a PATCH request to update a specific custom report associated with a store.
 *
 * @param {Object} params - The parameters for editing the report.
 * @param {string} params.storeId - The unique identifier for the store.
 * @param {string} params.reportId - The unique identifier for the report to be edited.
 * @param {FormData} params.formData - The form data containing report updates.
 * @returns {Promise<Response>} A promise that resolves with the response to the PATCH request.
 * @example
 * // To edit the report for a store with ID '123' and report ID 'abc':
 * editReport({ storeId: '123', reportId: 'abc', formData: new FormData() });
 */
export const editReport = async ({
  storeId,
  reportId,
  formData,
}: {
  storeId: string;
  reportId: string;
  formData: FormData;
}) => {
  const response = await fetch(
    `/stores/${storeId}/custom_reports/${reportId}`,
    {
      method: "PATCH",
      body: formData,
      headers: {
        ...headers,
        Accept: "application/json",
      },
    }
  );

  return response;
};

/**
 * Asynchronously sends a POST request to create a specific custom report associated with a store.
 *
 * @param {string} params.storeId - The unique identifier for the store.
 * @param {FormData} params.formData - The form data containing report updates.
 * @returns {Promise<Response>} A promise that resolves with the response to the PATCH request.
 * @example
 * // To edit the report for a store with ID '123' and report ID 'abc':
 * addReport({ storeId: '123', formData: new FormData() });
 */
export const addReport = async ({
  storeId,
  formData,
}: {
  storeId: string;
  formData: FormData;
}) => {
  const response = await fetch(`/stores/${storeId}/custom_reports`, {
    method: "POST",
    body: formData,
    headers: {
      ...headers,
      Accept: "application/json",
    },
  });

  return response;
};

/**
 * Asynchronously sends a DELETE request to delete a specific custom report associated with a store.
 *
 * @param {string} params.storeId - The unique identifier for the store.
 * @param {string} params.reportId - The report id to be deleted
 * @returns {Promise<Response>} A promise that resolves with the response to the PATCH request.
 * @example
 * // To edit the report for a store with ID '123' and report ID 'abc':
 * deleteReport({ storeId: '123', reportId: 'abc' });
 */
export const deleteReport = async ({
  storeId,
  reportId,
}: {
  storeId: string;
  reportId: string;
}) => {
  const response = await fetch(
    `/stores/${storeId}/custom_reports/${reportId}`,
    {
      method: "DELETE",
      headers: {
        ...headers,
        Accept: "application/json",
      },
    }
  );

  return response;
};

/**
 * Fetch a custom report.
 *
 * @param {string} storeId The unique identifier for the store to which the custom report belongs.
 * @param {string} reportId The unique identifier for the custom report being refreshed.
 *
 * @example
 * // To fetch the custom report for a store with ID '123' and custom report ID 'abc':
 * fetchCustomReport('123', 'abc');
 */
export const fetchCustomReport = (storeId: string, reportId: string) => {
  return fetch(`/stores/${storeId}/custom_reports/${reportId}`, {
    method: "GET",
    headers: {
      ...headers,
      Accept: "application/json",
    },
  });
};

/**
 * Update the sync status of a custom report.
 *
 * This function triggers a sync status update by making a GET request to the custom report endpoint.
 * IMPORTANT: The backend will handle updating the report's status to refreshable after processing.
 *
 * @param {CustomReport} report The custom report object containing store_id and id.
 * @returns {Promise<void>}
 *
 * @throws Will log an error if the fetch operation fails.
 *
 * @example
 * // To update the sync status of a custom report:
 * const report = { store_id: '123', id: 'abc' };
 * await updateSyncStatus(report);
 */
export const updateSyncStatus = async (report: CustomReport): Promise<void> => {
  try {
    await fetchCustomReport(report.store_id, report.id);
  } catch (error) {
    console.error("Error fetching custom report:", error);
  }
};

/**
 * Update the value of a custom report variable.
 *
 * This function sends a PATCH request to update the value of a specific variable
 * in a custom report.
 *
 * @param {string} storeId - The ID of the store.
 * @param {string} reportId - The ID of the custom report.
 * @param {string} variableId - The ID of the variable to update.
 * @param {VariableType["value"]} value - The new value for the variable.
 *
 * @returns {Promise<{ success: boolean; data?: any; error?: string }>} A promise that resolves to an object containing:
 *   - success: boolean indicating whether the operation was successful
 *   - data: (optional) the updated variable data if successful
 *   - error: (optional) error message if the operation failed
 *
 * @example
 * // To update a variable in a custom report:
 * const result = await updateVariableValue(
 *   '123', // storeId
 *   'abc', // reportId
 *   'var1', // variableId
 *   { date: '2023-07-01' } // value
 * );
 */
export const updateVariableValue = async (
  storeId: string,
  reportId: string,
  variableId: string,
  value: VariableType["value"]
): Promise<{ success: boolean; data?: any; error?: string }> => {
  try {
    const response = await fetch(
      `/stores/${storeId}/custom_reports/${reportId}/variables/${variableId}`,
      {
        method: "PATCH",
        headers: {
          ...headers,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          custom_report_variable: {
            value: JSON.stringify(value),
          },
        }),
      }
    );

    if (!response.ok) {
      throw new Error(await response.statusText);
    }

    const data = await response.json();
    return { success: true, data };
  } catch (error) {
    console.error("Error updating variable:", error);
    return {
      success: false,
      error:
        error instanceof Error ? error.message : "An unknown error occurred",
    };
  }
};
