import { useEffect, useCallback, useState } from "react";
import { LuSettings, LuAlertOctagon } from "react-icons/lu";
import { SiGooglesheets } from "react-icons/si";

import { cn } from "~/utils/tailwind";

import consumer from "~/channels/consumer";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/tooltip";
import TimeAgo from "~/components/time-ago";
import { Switch } from "~/components/switch";

import { CustomReport } from "../types";
import {
  CUSTOM_REPORT_POLLING_INTERVAL,
  fetchCustomReport,
  editReport,
  getGoogleSheetUrl,
  refreshReport,
} from "../data";
import { RefreshButton } from "../view/refreshButton";

export const CustomReportCard = ({
  report: initialReport,
}: {
  report: CustomReport;
}) => {
  const [report, setReport] = useState<CustomReport>(initialReport);
  const [isRefreshable, setIsRefreshable] = useState(
    report.status !== "RUNNING"
  );
  const [formState, setFormState] = useState({
    enabled: report?.enabled || false,
    errors: report?.errors || undefined,
  });

  const updateSyncStatus = async () => {
    try {
      // Sync status updates triggered by GET call to custom report endpoint
      await fetchCustomReport(report.store_id, report.id);
    } catch (error) {
      console.error("Error fetching custom report:", error);
    }
  };

  const handleSwitch = async (e: boolean) => {
    const formData = new FormData();
    formData.append("[custom_report][enabled]", e.valueOf().toString());

    const response = await editReport({
      storeId: report.store_id,
      reportId: report.id,
      formData: formData,
    });

    if (!response.ok) {
      response.json().then((data) => {
        setFormState((prevState) => ({
          ...prevState,
          enabled: report.enabled,
          errors: data,
        }));
      });
    }
  };

  const handleRefresh = useCallback(async () => {
    if (!isRefreshable) return;

    // To stop spam clicking
    setIsRefreshable(false);
    try {
      await refreshReport(report.store_id, report.id);
    } catch (error) {
      console.error("Refresh Report error:", error);
    } finally {
      // backend will update back to refreshable
    }
  }, [report, isRefreshable]);

  // Update custom report sync status using Census on initial load for all custom reports
  useEffect(() => {
    if (report.google_sheet_url) {
      updateSyncStatus();
    }
  }, []); // Empty dependency array to run this effect once on initial mount;

  useEffect(() => {
    const subscription = consumer.subscriptions.create(
      { channel: "CustomReportChannel", custom_report_id: report.id },
      {
        received(data: CustomReport) {
          const { status } = data;
          setReport({ ...report, ...data });

          if (status) {
            setIsRefreshable(status !== "RUNNING");
          }
        },
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [report]);

  useEffect(() => {
    // We only need to poll when a custom report is running and enabled (ie. syncing via Census)
    let interval: number;
    if (report.status === "RUNNING" && report.google_sheet_url) {
      interval = setInterval(updateSyncStatus, CUSTOM_REPORT_POLLING_INTERVAL);
    }

    return () => {
      clearInterval(interval);
    };
  }, [report.status]);

  return (
    <TooltipProvider>
      <div className="flex flex-col justify-between w-full bg-white border rounded @container h-full">
        <div className="flex flex-col justify-between gap-5 p-4">
          <div className="flex flex-col justify-between gap-1 pb-2 items-top">
            <p className="flex flex-row gap-1.5 justify-between overflow-hidden">
              <a
                href={`custom_reports/${report.id}`}
                title={report?.name}
                className={cn(
                  "font-medium leading-tight text-gray-800 truncate",
                  report.can_edit &&
                    "max-w-[80%] @[26rem]:max-w-80 @md:max-w-[85%]"
                )}
              >
                {report?.name}
              </a>
              {report.can_edit ? (
                <Switch
                  value={formState.enabled.toString()}
                  defaultChecked={report.enabled}
                  disabled={!isRefreshable || !report.google_sheet_url}
                  onCheckedChange={handleSwitch}
                />
              ) : null}
            </p>
            <p className="h-8 text-xs text-gray-500 line-clamp-2">
              {report?.description}
            </p>
          </div>

          <div className="flex h-5 gap-2 text-sm">
            {!report.google_sheet_url && (
              <span className="flex flex-row items-center gap-2 text-red-500">
                <LuAlertOctagon className="text-red-700" /> No Google Sheet URL
                added
              </span>
            )}
          </div>
        </div>

        <div className="flex flex-row flex-wrap justify-between h-full gap-4 p-4 border-t bg-gray-50">
          <div className="flex items-center min-w-48">
            <Tooltip>
              <TooltipTrigger asChild>
                <p className="text-xs text-gray-500">
                  <span>Last refreshed</span>{" "}
                  <TimeAgo timestamp={report.last_refreshed_at} />
                </p>
              </TooltipTrigger>
              <TooltipContent>
                {report.last_refreshed_at_formatted}
              </TooltipContent>
            </Tooltip>
          </div>

          <div className="flex flex-row items-center gap-2 h-9">
            <a
              href={getGoogleSheetUrl(report.google_sheet_url)}
              target="_blank"
              className={cn(
                "flex items-center gap-1.5 align-middle btn btn-sm h-full",
                !report.google_sheet_url && "cursor-not-allowed opacity-40"
              )}
              aria-disabled={!report.google_sheet_url}
            >
              <SiGooglesheets className="w-3.5 h-auto text-green-700" />
              View Google Sheet
            </a>

            <RefreshButton
              report={report}
              onClick={handleRefresh}
              disabled={!isRefreshable}
            />

            {report.can_edit ? (
              <a
                href={`custom_reports/${report.id}/edit`}
                className="p-2 btn btn-sm"
              >
                <LuSettings className="w-4 h-auto" />
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </TooltipProvider>
  );
};
