import React from "react";

import { cn } from "~/utils/tailwind";

import { Tooltip, TooltipContent, TooltipTrigger } from "~/components/tooltip";
import { Audience } from "./types";

const DownloadButton = ({
  audience,
  onClick,
  disabled,
}: {
  audience: Audience;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled: boolean;
}) => {
  return (
    <Tooltip>
      <TooltipTrigger
        className="px-3 py-2 btn btn-sm"
        onClick={onClick}
        disabled={disabled}
      >
        Download
      </TooltipTrigger>
      <TooltipContent
        className={cn(
          "flex flex-col gap-1 px-3 py-2 w-80",
          audience.status !== "FAILED" && "hidden"
        )}
      >
        <h5 className="text-xs font-semibold text-gray-800">
          Audience refresh failed
        </h5>
        <p className="text-xs text-gray-500">
          Click to download last successful audience from{" "}
          <span className="text-gray-800">{audience.last_refreshed_at_formatted}</span>.
        </p>
      </TooltipContent>
    </Tooltip>
  );
};

export default DownloadButton;
