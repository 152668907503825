import React from "react";
import {
  useReactTable,
  getExpandedRowModel,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
  createColumnHelper,
  Row,
  SortingState,
} from "@tanstack/react-table";
import { LuChevronDown, LuChevronUp } from "react-icons/lu";

import { cn } from "../utils/tailwind";
import {
  numberToHuman,
  numberToPercentage,
  numberToCurrency,
} from "../utils/number-formatters";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./tooltip";
import { getIconForChannel } from "./channelIcon";
import BarChart from "./charts/bar";

import ChannelIdentificationStatus from "./identificationStatus/channelStatus";
import statusConfig from "./identificationStatus/channelStatusConfig.json";

export type Channel = {
  type: string;
  name: keyof typeof statusConfig;
  newRevenue: string;
  returningRevenue: string;
  totalRevenue: string;
  conversionRate: string;
  identificationRate: string;
  subRows: Channel[];
};

const columnHelper = createColumnHelper<Channel>();
type ChannelsTableProps = {
  data: Channel[];
  currency: string;
};

const ChannelsTable = ({ data, currency }: ChannelsTableProps) => {
  const columns = React.useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Channel",
        minSize: 200,
        cell: ({ row, getValue }) => {
          return (
            <p
              className={cn(
                "flex flex-row items-center gap-2 text-gray-800",
                row?.parentId && "text-sm text-gray-600 -ml-2"
              )}
            >
              {row.getCanExpand() ? (
                <button
                  onClick={row.getToggleExpandedHandler()}
                  className={cn(
                    "px-1 mr-4 text-gray-500 border rounded hover:bg-gray-50 cursor-pointer",
                    row.getIsExpanded() && "bg-white"
                  )}
                >
                  <span
                    className={cn(
                      "inline-block transition transform duration-300",
                      row.getIsExpanded() ? "rotate-0" : "-rotate-90"
                    )}
                  >
                    <LuChevronDown size="14" />
                  </span>
                </button>
              ) : (
                <span
                  className={cn(
                    "w-10",
                    !table.getCanSomeRowsExpand() && "hidden"
                  )}
                ></span>
              )}
              <span>{getIconForChannel(getValue())}</span>
              {getValue()}
            </p>
          );
        },
      }),
      columnHelper.accessor("totalRevenue", {
        header: () => <p className="w-full text-right">Revenue</p>,
        cell: ({ getValue, row }) => (
          <Tooltip>
            <TooltipTrigger
              className={cn(
                "w-full text-right text-sm text-gray-800",
                row?.parentId && "text-gray-600"
              )}
            >
              {numberToHuman(getValue(), {
                style: "currency",
                currency: currency,
              })}
            </TooltipTrigger>
            <TooltipContent align="end">
              {numberToCurrency(getValue(), {
                currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </TooltipContent>
          </Tooltip>
        ),
      }),
      {
        accessorKey: "revenueChart",
        header: "New/Returning",
        enableSorting: false,
        size: 150,
        cell: ({ row }: { row: Row<Channel> }) => {
          const maxValue = parseFloat(row.original.totalRevenue) || 0;
          const chartData = {
            returningRevenue: parseFloat(row.original.returningRevenue) || 0,
            newRevenue: parseFloat(row.original.newRevenue) || 0,
          };
          const newRevenue = numberToCurrency(row.original.newRevenue, {
            currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          const returningRevenue = numberToCurrency(
            row.original.returningRevenue,
            {
              currency,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );

          return (
            <Tooltip>
              <TooltipTrigger>
                <div className="relative h-8 w-44">
                  <BarChart chartData={chartData} maxValue={maxValue} />
                </div>
              </TooltipTrigger>
              <TooltipContent align="start" className="min-w-[180px]">
                <p className="flex justify-between w-full">
                  <span className="block text-blue-500">New:</span>{" "}
                  <span className="block">{newRevenue}</span>
                </p>
                <p className="flex justify-between w-full">
                  <span className="block text-purple-500">Returning:</span>{" "}
                  <span className="block">{returningRevenue}</span>
                </p>
              </TooltipContent>
            </Tooltip>
          );
        },
      },
      columnHelper.accessor("conversionRate", {
        size: 170,
        header: () => (
          <p className="w-full text-right min-w-28">Conversion Rate</p>
        ),
        cell: ({ row, getValue }) => {
          const rawValue = getValue();
          const value = parseFloat(rawValue) * 100 || 0;

          if (!!row.parentId) return;

          return (
            <Tooltip>
              <TooltipTrigger className="w-full text-sm text-right text-gray-800">
                {numberToPercentage(value, value >= 10 ? 1 : 2)}
              </TooltipTrigger>
              <TooltipContent className="w-full" align="end">
                {numberToPercentage(value, 2)}
              </TooltipContent>
            </Tooltip>
          );
        },
      }),
      columnHelper.accessor("identificationRate", {
        enableSorting: false,
        size: 250,
        header: "Identification",
        cell: ({ row, getValue }) => {
          if (row.getParentRow()) return;

          const value = parseFloat(getValue());
          const valueFormatted = numberToPercentage(value * 100, 2);

          return (
            <Tooltip>
              <TooltipTrigger className="w-full">
                <ChannelIdentificationStatus
                  channel={row.original.name}
                  value={value}
                  showBorder={!row.parentId && row.getIsExpanded()}
                />
              </TooltipTrigger>
              <TooltipContent
                align="end"
                className="flex flex-col w-full max-w-xs gap-2 text-pretty"
              >
                <p className="text-base font-semibold">Identification</p>
                <p className="pb-1 text-gray-500">
                  A measure of known (identified) users visiting your store via
                  a specific channel. Solve produces this by reviewing channel
                  traffic patterns across our customers.
                </p>
                <div className="grid justify-between grid-cols-7 pt-1.5 text-sm text-gray-800 border-t border-gray-200">
                  <p className="col-span-4 font-semibold">
                    Identification Rate
                  </p>
                  <p className="col-span-3 text-right">{valueFormatted}</p>
                </div>
              </TooltipContent>
            </Tooltip>
          );
        },
      }),
    ],
    [data, currency]
  );

  const [expanded, setExpanded] = React.useState({});
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const table = useReactTable({
    columns,
    data,
    state: {
      expanded,
      sorting,
    },
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });
  const rows = table.getRowModel().rows;

  if (data.length === 0) {
    return (
      <div className="w-full h-[197px] bg-white">
        <p className="flex items-center justify-center h-full text-gray-500">
          No channels data available
        </p>
      </div>
    );
  }

  const totalRevenue = data.reduce(
    (sum, channel) => sum + (parseFloat(channel.totalRevenue) || 0),
    0
  );

  return (
    <TooltipProvider>
      <table className="w-full">
        <thead className="border-b">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={cn(
                    "px-6 py-6 text-sm font-normal text-left text-gray-500",
                    header.id == "revenueChart" && "pr-0",
                    header.id == "conversionRate" && "pl-0"
                  )}
                  style={{
                    width:
                      header.getSize() === Number.MAX_SAFE_INTEGER
                        ? "auto"
                        : `${header.getSize()}px`,
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      className={cn(
                        header.column.getCanSort() &&
                          "flex flex-row items-center gap-1 cursor-pointer select-none relative"
                      )}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      <span
                        className={cn(
                          "absolute text-gray-800",
                          header.id == "name" ? "left-[60px]" : "-right-5"
                        )}
                      >
                        {{
                          asc: <LuChevronUp />,
                          desc: <LuChevronDown />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </span>
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white">
          {rows.map((row, index) => {
            const isExpandedLeaf = row.getParentRow()?.getIsExpanded();
            const isParentRow = row.getLeafRows().length > 0;
            const isExpandedParent = row.getIsExpanded();

            const isLastRowInTable = index === rows.length - 1;
            const isDepthDifferentFromNext =
              isLastRowInTable || row.depth !== rows[index + 1].depth;
            const isLastRowInGroup =
              isLastRowInTable || isDepthDifferentFromNext;

            return (
              <tr
                key={row.id}
                className={cn(
                  "border-gray-200",
                  isExpandedParent && "bg-gray-50",
                  isParentRow && isExpandedParent && "border-y",
                  isLastRowInGroup && isExpandedLeaf && "border-b shadow-sm"
                )}
              >
                {row.getVisibleCells().map((cell) => {
                  const currentColumn = cell.column.columnDef.header;
                  return (
                    <td
                      style={{
                        width:
                          cell.column.getSize() === Number.MAX_SAFE_INTEGER
                            ? "auto"
                            : `${cell.column.getSize()}px`,
                      }}
                      key={cell.id}
                      className={cn(
                        "h-full px-6 py-0 my-0 whitespace-nowrap",
                        isExpandedLeaf &&
                          currentColumn == "Channel" &&
                          "pl-[34px]"
                      )}
                    >
                      <div
                        className={cn(
                          "h-full py-4",
                          isExpandedLeaf &&
                            currentColumn == "Channel" &&
                            "flex border-l"
                        )}
                      >
                        <span className="flex items-center h-8">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </span>
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot className="border-t bg-gray-50">
          <tr>
            <td className="px-6 py-4 font-medium">Total Revenue</td>
            <td className="text-right">
              <Tooltip>
                <TooltipTrigger className="w-full px-6 font-medium text-right text-gray-800">
                  {numberToHuman(totalRevenue, {
                    style: "currency",
                    currency: currency,
                  })}
                </TooltipTrigger>
                <TooltipContent align="end" alignOffset={24}>
                  {numberToCurrency(totalRevenue, {
                    currency,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </TooltipContent>
              </Tooltip>
            </td>
            <td colSpan={columns.length}></td>
          </tr>
        </tfoot>
      </table>
    </TooltipProvider>
  );
};

export default ChannelsTable;
