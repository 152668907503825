import { useState, useMemo } from "react";
import { LuCheck, LuChevronDown, LuPlusCircle, LuStore } from "react-icons/lu";
import { Turbo } from "@hotwired/turbo-rails";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "~/components/command";
import { Popover, PopoverContent, PopoverTrigger } from "~/components/popover";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/tooltip";
import { cn } from "~/utils/tailwind";
import ShopifyIcon from "~/icons/shopify.svg?react";

type Store = {
  id: string;
  name: string;
  store_type: string;
  account: {
    id: string;
    name: string;
  };
};

interface StoreSwitcherProps {
  stores: Store[];
  current_store: Store;
  can_create: boolean;
  className?: string;
}

const StoreDisplay = ({ store }: { store: Store }) => (
  <div className="flex items-center text-left truncate gap-1.5 text-sm">
    {store.store_type === "shopify" ? (
      <ShopifyIcon className="size-5 shrink-0 text-gray-400" />
    ) : (
      <LuStore className="size-5 shrink-0 text-gray-400" />
    )}
    <span className="truncate">{store.name}</span>
  </div>
);

const SingleStoreView = ({ current_store }: { current_store: Store }) => (
  <div className="w-56 h-9 bg-gray-700/50 rounded-md flex flex-row justify-between items-center px-3 pt-1 pb-1.5 pr-3.5">
    <p className="flex flex-col w-auto overflow-hidden text-left pr-2">
      <span className="text-xs text-white truncate min-w-[80px]">
        {current_store ? current_store.name : "Store Select"}
      </span>
      <span className="text-gray-400 text-[10px] leading-none truncate">
        {current_store ? current_store.account.name : "View a store"}
      </span>
    </p>
  </div>
);

const CreateStoreButton = ({
  onCreateStore,
}: {
  onCreateStore: () => void;
}) => (
  <div className="border-t border-gray-200">
    <button
      onClick={onCreateStore}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          onCreateStore();
        }
      }}
      className="w-full flex px-3 py-2.5 cursor-default items-center hover:text-blue-500 rounded-sm text-sm data-[disabled=true]:pointer-events-none data-[disabled=true]:bg-gray-100 data-[selected='true']:bg-gray-100 data-[selected=true]:text-gray-900 focus:text-blue-500"
    >
      <LuPlusCircle className="mr-2 size-5" />
      Create store
    </button>
  </div>
);

export default function StoreSwitcher({
  stores,
  current_store,
  can_create,
  className,
}: StoreSwitcherProps) {
  const [open, setOpen] = useState(false);

  const groups = useMemo(() => {
    if (!stores) return [];
    return formatGroupedStores(groupStoresByAccount(stores));
  }, [stores]);

  const handleCreateStore = () => {
    Turbo.visit("/stores/new");
    setOpen(false);
  };

  const handleStoreSelect = (storeId: string) => {
    Turbo.visit(`/stores/${storeId}`);
    setOpen(false);
  };

  if (stores.length === 1 && !can_create) {
    return <SingleStoreView current_store={current_store} />;
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <button
          type="button"
          role="combobox"
          aria-expanded={open}
          aria-label="Select a store"
          className={cn(
            "w-60 flex flex-row px-3 pr-3.5 pb-1.5 pt-1 h-9 bg-gray-700/50 max-w-56 items-center text-left rounded-md shadow-sm text-sm font-normal group !ring-0 !outline-0 hover:bg-gray-600/50 focus:bg-gray-600/50",
            className
          )}
        >
          <p className="flex flex-col w-auto overflow-hidden text-left pr-2">
            <span className="text-xs text-white truncate min-w-[80px]">
              {current_store?.name ?? "Store Select"}
            </span>
            <span className="text-gray-400 text-[10px] leading-none truncate">
              {current_store?.account?.name ?? "View a store"}
            </span>
          </p>
          <LuChevronDown
            className={cn(
              "ml-auto size-4 shrink-0 text-white transition",
              open && "rotate-180"
            )}
          />
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
        <Command style={{ overflow: "auto" }}>
          <CommandInput
            placeholder="Search store..."
            className="border-none focus:ring-0 px-0"
          />
          <CommandList className="max-h-64">
            <CommandEmpty>No store found.</CommandEmpty>

            {groups.map((group) => (
              <CommandGroup key={group.label} heading={group.label}>
                {group.stores?.map((store) => (
                  <TooltipProvider key={store.id}>
                    <CommandItem
                      onSelect={() => handleStoreSelect(store.id)}
                      className="gap-1.5"
                    >
                      <Tooltip>
                        <TooltipTrigger className="flex items-center text-left truncate gap-1.5 text-sm">
                          <StoreDisplay store={store} />
                        </TooltipTrigger>
                        <TooltipContent side="bottom">
                          <span>{store.name}</span>
                        </TooltipContent>
                      </Tooltip>
                      <LuCheck
                        className={cn(
                          "ml-auto size-4",
                          current_store?.id === store.id
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  </TooltipProvider>
                ))}
              </CommandGroup>
            ))}
          </CommandList>

          {can_create ? (
            <CreateStoreButton onCreateStore={handleCreateStore} />
          ) : null}
        </Command>
      </PopoverContent>
    </Popover>
  );
}

// Groups stores by their account name
// Returns an object where each key is an account name and value is an array of stores
const groupStoresByAccount = (stores: Store[]) => {
  const grouped: Record<string, Store[]> = {};

  for (const store of stores) {
    const accountName = store.account.name;
    
    if (!grouped[accountName]) {
      grouped[accountName] = [];
    }

    grouped[accountName].push(store);
  }

  return grouped;
};

// Transforms stores grouped by account into an array of labeled account groups
const formatGroupedStores = (groupedStores: Record<string, Store[]>) => {
  return Object.entries(groupedStores).map(([accountName, stores]) => ({
    label: accountName,
    stores,
  }));
};
