import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="automations--content-block-form"
export default class extends Controller {
  static targets = [
    "form",
    "typeSelect",
    "plannedFields",
    "plannedWithTimeFields"
  ];

  connect() {
    this.validateInputs();
  }

  validateInputs() {
    if (!this.formTarget) {
      throw new Error(
        `form target for data-controller="automations--content-block-form" must be set`
      );
    }

    if (!this.typeSelectTarget) {
      throw new Error(
        `typeSelect target for data-controller="automations--content-block-form" must be set`
      );
    }

    if (!this.plannedFieldsTarget) {
      throw new Error(
        `plannedFields target for data-controller="automations--content-block-form" must be set`
      );
    }

    if (!this.plannedWithTimeFieldsTarget) {
      throw new Error(
        `plannedWithTimeFields target for data-controller="automations--content-block-form" must be set`
      );
    }
  }

  toggleType() {
    const isRange = this.typeSelectTarget.value === "planned";

    this.plannedFieldsTarget.classList.toggle("hidden", !isRange);
    this.plannedWithTimeFieldsTarget.classList.toggle("hidden", isRange);
  }

  resetUnselected() {
    const isRange = this.typeSelectTarget.value === "planned";

    if (isRange) {
      this.clearPlannedWithTimeInput();
    } else {
      this.clearDateRange();
    }
  }

  clearDateRange() {
    this.plannedFieldsTarget.querySelector(
      'input[name*="schedule_starts_at"]'
    ).value = "";
    this.plannedFieldsTarget.querySelector(
      'input[name*="schedule_ends_at"]'
    ).value = "";
  }

  clearPlannedWithTimeInput() {
    this.plannedWithTimeFieldsTarget.querySelector(
      'input[name*="scheduled_at"]'
    ).value = "";
  }
}
