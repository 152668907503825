import { useEffect, useMemo, useRef } from "react";
import { CustomReport } from "../../types";
import { CUSTOM_REPORT_POLLING_INTERVAL, updateSyncStatus } from "../../data";
import { createReportSubscription } from "./helpers";

/**
 * Update custom report sync status using Census on initial load for all custom reports.
 */
export const useInitialSyncStatusEffect = (combinedReports: CustomReport[]) => {
  useEffect(() => {
    combinedReports.forEach((report) => {
      if (report.google_sheet_url) {
        updateSyncStatus(report);
      }
    });
  }, []);
};

/**
 * Setup subscriptions for the custom report, one subscription for each query.
 */
export const useSubscriptionEffect = (
  combinedReports: CustomReport[],
  updateReportData: (data: CustomReport) => void
) => {
  const subscriptionsRef = useRef<Record<string, any>>({});
  const reportIds = useMemo(
    () => combinedReports.map((report) => report.id),
    [combinedReports]
  );

  useEffect(() => {
    const currentReportIds = new Set(reportIds);

    // Create new subscriptions and remove old ones
    combinedReports.forEach((report) => {
      if (!subscriptionsRef.current[report.id]) {
        subscriptionsRef.current[report.id] = createReportSubscription(
          report,
          updateReportData
        );
      }
    });

    Object.keys(subscriptionsRef.current).forEach((id) => {
      if (!currentReportIds.has(id)) {
        subscriptionsRef.current[id].unsubscribe();
        delete subscriptionsRef.current[id];
      }
    });

    // Cleanup function
    return () => {
      Object.values(subscriptionsRef.current).forEach((sub) =>
        sub.unsubscribe()
      );
      subscriptionsRef.current = {}; // Reset the subscriptionsRef
    };
  }, [reportIds, updateReportData]);
};

const shouldPoll = (report: CustomReport) =>
  report.status === "RUNNING" && report.google_sheet_url;

/**
 * Regularly update the sync status for the custom report.
 */
export const usePollingEffect = (combinedReports: CustomReport[]) => {
  const intervalsRef = useRef<Record<string, number>>({});

  useEffect(() => {
    // Create or update intervals for running reports
    combinedReports.forEach((report) => {
      if (shouldPoll(report)) {
        clearInterval(intervalsRef.current[report.id]);
        intervalsRef.current[report.id] = setInterval(
          () => updateSyncStatus(report),
          CUSTOM_REPORT_POLLING_INTERVAL
        );
      } else {
        clearInterval(intervalsRef.current[report.id]);
        delete intervalsRef.current[report.id];
      }
    });

    // Clear intervals for removed reports
    Object.keys(intervalsRef.current).forEach((id) => {
      if (!combinedReports.some((report) => report.id === id)) {
        clearInterval(intervalsRef.current[id]);
        delete intervalsRef.current[id];
      }
    });

    // Cleanup function to clear intervals
    return () => Object.values(intervalsRef.current).forEach(clearInterval);
  }, [combinedReports]);
};
