import { Controller } from "@hotwired/stimulus";

import React from "react";
import { createRoot } from "react-dom/client";

import ChannelsTable from "../components/channelsTable";

// Connects to data-controller="channels-revenue"
export default class extends Controller {
  static values = {
    url: String,
    currency: String,
  };

  static targets = ["loading", "error"]

  async connect() {
    if (!this.urlValue.endsWith(".json")) {
      throw new Error(
        `Invalid URL for data-controller="channels-revenue": must end with .json`
      );
    }

    if (!this.currencyValue) {
      throw new Error(
        `Default currency value for data-controller="channels-revenue" must be set`
      );
    }

    const url = new URL(this.urlValue, window.location.href);
    url.search = new URLSearchParams(window.location.search);

    try {
      this.currency = this.setCurrency();

      const response = await fetch(url);
      const data = await response.json();

      createRoot(this.element).render(
        <ChannelsTable data={data} currency={this.currency} />
      );
    } catch (error) {
      console.error("Error loading channels revenue data:", error);
      this.errorTarget.classList.add("flex");
      this.errorTarget.classList.remove("hidden");
    } finally {
      this.loadingTarget.classList.add("hidden");
    }
  }

  setCurrency() {
    const currencyParam = new URLSearchParams(window.location.search).get(
      "currency"
    );

    if (!!currencyParam) {
      return currencyParam;
    } else {
      return this.currencyValue;
    }
  }
}
