const headers = {
  "X-Csrf-Token":
    document.querySelector("[name='csrf-token']")?.getAttribute("content") ??
    "",
};

/**
 *  How often we poll for changes to an audience in ms.
 *
 *  Currently: 2 minutes
 */
export const AUDIENCE_POLLING_INTERVAL = 2 * 60 * 1000;

/**
 * Download an audience CSV file for a given audience.
 *
 * @param {string} storeId The unique identifier for the store to which the audience belongs.
 * @param {string} audienceId The unique identifier for the audience being refreshed.
 *
 * @example
 * // To download the audience for a store with ID '123' and audience ID 'abc':
 * downloadCsv('123', 'abc');
 */
export const downloadCsv = async (storeId: string, audienceId: string) => {
  return fetch(`/stores/${storeId}/audiences/${audienceId}/results_url`)
    .then((response) => response.json())
    .then((data) => {
      const presignedUrl = data.results_url;
      window.open(presignedUrl, "_blank");
    })
    .catch((error) => console.error("Download CSV Error:", error));
};

/**
 * Refresh an audience download.
 *
 * @param {string} storeId The unique identifier for the store to which the audience belongs.
 * @param {string} audienceId The unique identifier for the audience being refreshed.
 *
 * @example
 * // To refresh the audience for a store with ID '123' and audience ID 'abc':
 * refreshAudience('123', 'abc');
 */
export const refreshAudience = (storeId: string, audienceId: string) => {
  fetch(`/stores/${storeId}/run_audience_query?id=${audienceId}`, {
    method: "POST",
    headers,
  }).catch((error) => console.error("Refresh Audience error:", error));
};

/**
 * Toggle an audience on or off.
 *
 * @param {boolean} currentState The current state of the audience. True for enabled (active) and false for disabled (inactive).
 * @param {string} storeId The unique identifier for the store to which the audience belongs.
 * @param {string} audienceId The unique identifier for the audience being toggled.
 *
 * @example
 * // To toggle the audience on for a store with ID '123' and audience ID 'abc':
 * toggleAudience(false, '123', 'abc');
 */
export const toggleAudience = (
  currentState: boolean,
  storeId: string,
  audienceId: string
) => {
  // Toggle action will always be the opposite of the current state.
  const action = currentState ? "disable_klaviyo_sync" : "enable_klaviyo_sync";

  fetch(`/stores/${storeId}/audiences/${audienceId}/${action}`, {
    method: "POST",
    headers,
  }).catch((error) => console.error("Toggle Audience error:", error));
};

/**
 * Fetch an audience.
 *
 * @param {string} storeId The unique identifier for the store to which the audience belongs.
 * @param {string} audienceId The unique identifier for the audience being refreshed.
 *
 * @example
 * // To fetch the audience for a store with ID '123' and audience ID 'abc':
 * fetchAudience('123', 'abc');
 */
export const fetchAudience = (storeId: string, audienceId: string) => {
  return fetch(`/stores/${storeId}/audiences/${audienceId}`, {
    method: "GET",
    headers: {
      ...headers,
      Accept: "application/json",
    },
  });
};
