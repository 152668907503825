import { Query } from "./query";
import { CustomReport } from "../../types";
import { refreshReport } from "../../data";
import { useReportManagement } from "../hooks/useReportManagement";

export const Queries = ({ reports }: { reports: CustomReport[] }) => {
  const { isAllRefreshable, toggleIndividual } = useReportManagement();
  const isRefreshDisabled = !isAllRefreshable;

  const handleRefreshAll = async () => {
    try {
      // Refresh all reports
      // check is refresh is already running first before refreshing
      // if refreshing is already running, then don't refresh
      for (const report of reports) {
        if (report.status !== "RUNNING") {
          toggleIndividual(report.id);
          await refreshReport(report.store_id, report.id);
        }
      }
    } catch (error) {
      console.error("Refresh all error:", error);
    }
  };

  return (
    <div className="mt-8 flex flex-col gap-4 p-5 border rounded">
      <div className="flex items-center justify-between">
        <h2 className="text-base font-medium">Report Data</h2>

        {reports.length > 0 ? (
          <button
            disabled={isRefreshDisabled}
            className="btn btn-sm btn-primary"
            onClick={handleRefreshAll}
          >
            Refresh All
          </button>
        ) : null}
      </div>

      <div className="flex flex-col">
        {reports.map((report) => (
          <Query report={report} key={report.id} />
        ))}
      </div>
    </div>
  );
};
