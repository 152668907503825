import { Controller } from "stimulus";

export default class extends Controller {
  static values = { name: String };

  connect() {
    if (!this.nameValue) {
      throw new Error(
        `Name value for data-controller="disable-fields" must be set`
      );
    }

    this.inputElements = this.element.querySelectorAll(
      `input[data-disable-fields-name=${this.nameValue}]`
    );
    this.selectElements = this.element.querySelectorAll(
      `select[data-disable-fields-name=${this.nameValue}]`
    );
    this.divElements = this.element.querySelectorAll(
      `div[data-disable-fields-name=${this.nameValue}]`
    );

    if (
      this.inputElements.length === 0 &&
      this.selectElements.length === 0 &&
      this.divElements.length === 0
    ) {
      throw new Error(
        `No input, select, or div targets found for data-disable-fields-name="${this.nameValue}"`
      );
    }

    this.toggleFields();
  }

  toggleFields() {
    const enabled = this.data.get("enabled") === "true";

    this.inputElements.forEach((input) => {
      input.disabled = !enabled;
      input.classList.toggle("pointer-events-none", !enabled);
      input.classList.toggle("select-none", !enabled);
    });
    this.selectElements.forEach((select) => {
      select.disabled = !enabled;
      select.classList.toggle("pointer-events-none", !enabled);
      select.classList.toggle("select-none", !enabled);
    });
    this.divElements.forEach((div) => {
      div.classList.toggle("opacity-20", !enabled);
      div.classList.toggle("pointer-events-none", !enabled);
      div.classList.toggle("select-none", !enabled);
    });
  }

  checkboxChanged(event) {
    const checkbox = event.target;
    this.data.set("enabled", checkbox.checked);
    this.toggleFields();
  }
}
