import consumer from "~/channels/consumer";

import { CustomReport } from "../../types";

export const createCombinedReports = (report: CustomReport): CustomReport[] => [
  report,
  ...report.child_reports.map((childReport) => ({
    ...childReport,
    can_edit: report.can_edit,
    can_view_query: report.can_view_query,
  })),
];

export const createReportSubscription = (
  report: CustomReport,
  updateReportCallback: (data: CustomReport) => void
) =>
  consumer.subscriptions.create(
    { channel: "CustomReportChannel", custom_report_id: report.id },
    { received: updateReportCallback }
  );

export const isAnyReportRunning = (reports: CustomReport[]): boolean =>
  reports.some((report) => report.status === "RUNNING");
