import { useCallback } from "react";

import TimeAgo from "~/components/time-ago";

import { useReportManagement } from "../hooks/useReportManagement";

import { CustomReport } from "../../types";
import { refreshReport } from "../../data";
import { RefreshButton } from "../refreshButton";
import { QueryFailures } from "./failures";
import { CodePreview } from "./codePreview";
import { MoreOptions } from "./moreOptions";
import { Controls } from "./controls";

type ReportQueryProps = {
  report: CustomReport;
};

export const Query = ({ report }: ReportQueryProps) => {
  const { isAllRefreshable, disabledQueries, toggleIndividual } =
    useReportManagement();
  const isRefreshDisabled = disabledQueries[report.id] || !isAllRefreshable;

  const handleRefresh = useCallback(async () => {
    toggleIndividual(report.id);

    try {
      await refreshReport(report.store_id, report.id);
    } catch (error) {
      console.error("Query refresh report error:", error);
    } finally {
      // backend will update back to refreshable
    }
  }, [report.id, report.store_id, toggleIndividual]);

  return (
    <div className="flex flex-col justify-between border-t py-4 last:pb-0 gap-4">
      <div className="flex flex-row items-center justify-between gap-6">
        <div className="flex flex-col gap-1 text-sm truncate">
          <p
            className="max-w-xl truncate text-sm font-medium text-gray-800"
            title={report.google_sheet_tab}
          >
            {report.google_sheet_tab}
          </p>

          <p className="max-w-xl truncate text-xs text-gray-500">
            Last refreshed <TimeAgo timestamp={report.last_refreshed_at} />
          </p>
        </div>

        <div className="flex flex-row items-center gap-2">
          <RefreshButton
            report={report}
            onClick={handleRefresh}
            disabled={isRefreshDisabled}
          />

          {report.can_edit ? <MoreOptions report={report} /> : null}
        </div>
      </div>

      <QueryFailures report={report} />
      <Controls
        report={{
          report_id: report.id,
          store_id: report.store_id,
          can_view_variables: report.can_view_variables,
        }}
        controls={report.variables}
      />
      <CodePreview report={report} />
    </div>
  );
};
