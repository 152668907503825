import "@hotwired/turbo-rails";
import "../controllers";

import "prismjs";
import "prismjs/components/prism-sql";

import "../assets/stylesheets/prism.css";
import "../assets/stylesheets/datepicker.css";

import.meta.glob(["../assets/fonts/*", "../images/**/*", "../logos/**/*", "../icons/**/*"], {
  eager: true,
});

// this enables ActiveStorage DOM events, we use `direct-upload:end` to
// trigger the csv import background job
import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();
