import { LuAlertOctagon } from "react-icons/lu";
import { CustomReport } from "../../types";

export const QueryFailures = ({ report }: { report: CustomReport }) => {
  return (
    <>
      {report.status === "FAILED" ? (
        <div className="flex flex-col w-full p-4 mt-5 text-red-700 rounded bg-red-50">
          <p className="flex flex-row items-center gap-3 text-sm font-medium">
            <LuAlertOctagon className="w-4 h-4 text-red-700" />
            Failed to refresh report
          </p>
          <div className="flex flex-col gap-1 pt-2 text-sm empty:hidden">
            {report.failures}
          </div>
        </div>
      ) : null}
    </>
  );
};
