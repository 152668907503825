import { demoData } from "./demoData";

const headers = {
  "X-Csrf-Token":
    document.querySelector("[name='csrf-token']")?.getAttribute("content") ?? ""
};

export const getFrequencyPreview = (storeId: string, value: number) => {
  try {
    // TODO: Fetch and return with real data
    return demoData(value);
  } catch (error) {
    console.error("Get Frequency Preview Request error:", error);
    throw error;
  }
};

export const getFrequency = async (storeId: string): Promise<string> => {
  return fetch(`/stores/${storeId}/settings/automations/frequency`, {
    headers: {
      ...headers,
      "content-type": "application/json"
    }
  })
    .then((res) => res.json())
    .then((frequency) => {
      return frequency;
    })
    .catch((error) => {
      console.error("Update Frequency Request error:", error);
      throw error;
    });
};

// we need to pass the value for the setting as a string for the backend
export const updateFrequency = async (storeId: string, value: string) => {
  await fetch(`/stores/${storeId}/settings/automations`, {
    method: "PUT",
    headers: {
      ...headers,
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify({
      store: { settings_automations_frequency: value }
    })
  }).catch((error) => {
    console.error("Update Frequency Request error:", error);
    throw error;
  });

  // TODO: return real data
  return demoData(Number.parseInt(value));
};
