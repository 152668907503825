import React from "react";

import { cn } from "../../utils/tailwind";

import statusConfig from "./channelStatusConfig.json";
import type { Channel } from "../channelsTable";

type Status = "Low" | "Medium" | "High";

function determineStatus(channel: Channel["name"], value: number): Status {
  const channelConfig = statusConfig[channel] || statusConfig["Other"];
  let { min, max } = channelConfig;

  if (value > max) {
    return "High";
  } else if (value >= min && value <= max) {
    return "Medium";
  } else {
    return "Low";
  }
}

function getColorClasses(rating: Status): string {
  switch (rating) {
    case "Low":
      return "bg-orange-50 text-orange-700";
    case "Medium":
      return "bg-green-50 text-green-700";
    case "High":
      return "bg-blue-50 text-blue-700";
    default:
      return "bg-orange-50 text-orange-700";
  }
}

interface ChannelIdentificationStatusProps {
  channel: keyof typeof statusConfig | Channel["name"];
  value: number;
  showBorder: boolean;
}

const ChannelIdentificationStatus = ({
  channel,
  value,
  showBorder = false,
}: ChannelIdentificationStatusProps) => {
  const rating = determineStatus(channel, value);
  const colorClasses = getColorClasses(rating);

  return (
    <div className="min-w-24">
      <div
        className={cn(
          "flex flex-row items-center px-3 py-1.5 rounded gap-3 w-full",
          colorClasses,
          showBorder && "border border-current"
        )}
      >
        <p className="mx-auto text-base font-medium">{rating}</p>
      </div>
    </div>
  );
};

export default ChannelIdentificationStatus;
