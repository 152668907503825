import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["modal", "trigger", "content"];

  connect() {
    // Listen for the ESC key to close the modal
    this.escKeyListener = (event) => {
      if (event.key === "Escape") {
        this.close();
      }
    };
    this.boundHandleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener("click", this.boundHandleClickOutside);
    document.addEventListener("keydown", this.escKeyListener);
  }

  disconnect() {
    document.removeEventListener("click", this.boundHandleClickOutside);
    document.removeEventListener("keydown", this.escKeyListener);
  }

  open(event) {
    event.preventDefault();
    this.modalTarget.classList.add("flex");
    this.modalTarget.classList.remove("hidden");
    // Prevent scrolling on the body when modal is open
    document.body.style.overflow = "hidden";
  }

  close(event) {
    if (event) {
      event.preventDefault();
    }
    this.modalTarget.classList.remove("flex");
    this.modalTarget.classList.add("hidden");
    // Re-enable scrolling on the body when modal is closed
    document.body.style.overflow = "";
  }

  handleClickOutside(event) {
    if (
      !this.contentTarget.contains(event.target) &&
      event.target !== this.triggerTarget
    ) {
      this.close();
    }
  }
}
