import { useState } from "react";

/**
 * A hook to create a dialog.
 * @returns A dialog object with a trigger function to open the dialog and a dismiss function to close it.
 * @example
 * const { props, trigger, dismiss } = useDialog();
 * return (
 *  <>
 *    <button onClick={trigger}>Open Dialog</button>
 *
 *    <Dialog {...props}>
 *      <DialogTitle>Dialog Title</DialogTitle>
 *      <DialogContent>
 *       <p>Dialog content goes here.</p>
 *       <button onClick={dismiss}>Close</button>
 *      </DialogContent>
 *    </Dialog>
 *  </>
 * );
 */
export function useDialog() {
  const [isOpen, setIsOpen] = useState(false);

  const trigger = () => setIsOpen(true);

  return {
    props: {
      open: isOpen,
      onOpenChange: setIsOpen,
    },
    trigger: trigger,
    dismiss: () => setIsOpen(false),
  };
}
