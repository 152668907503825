import { useMemo } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { format } from "date-fns-tz";
import { parse, startOfWeek, getDay } from "date-fns";
import enUS from "date-fns/locale/en-US";

import {
  ColoredDateCellWrapper,
  CustomToolbar,
  eventWrapper,
} from "./customComponents";
import { TooltipProvider } from "~/components/tooltip";

import "react-big-calendar/lib/css/react-big-calendar.css";

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export type CalendarEvent = {
  id: string;
  title: string;
  description: string;
  schedule_formatted: string;
  start: string;
  end: string;
  enabled: boolean;
};

interface Props {
  events: CalendarEvent[];
}

const CalendarSelect = ({ events }: Props) => {
  const { components, defaultDate, eventPropGetter } = useMemo(
    () => ({
      components: {
        timeSlotWrapper: ColoredDateCellWrapper,
        toolbar: CustomToolbar,
        eventWrapper: eventWrapper,
      },
      defaultDate: new Date(),
      eventPropGetter: (
        event: CalendarEvent,
        _start: Date,
        _end: Date,
        isSelected: boolean
      ) => {
        return {
          className: "!text-white !bg-blue-500",
          ...(isSelected && {
            className: "!text-white !bg-blue-600",
          }),
          ...(!event.enabled && {
            className: "!text-white !bg-gray-400",
          }),
          ...(!event.enabled &&
            isSelected && {
              className: "!text-white !bg-gray-500",
            }),
        };
      },
    }),
    []
  );

  return (
    <TooltipProvider>
      <Calendar
        components={components}
        defaultDate={defaultDate}
        events={events}
        localizer={localizer}
        showMultiDayTimes
        tooltipAccessor={null}
        step={60}
        views={["month"]}
        className="border rounded"
        eventPropGetter={eventPropGetter}
      />
    </TooltipProvider>
  );
};

export default CalendarSelect;
