import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from "~/components/tooltip";
import React from "react";

import { ToolbarProps } from "react-big-calendar";
import { LuArrowLeft, LuArrowRight } from "react-icons/lu";

export const ColoredDateCellWrapper = ({ children }: any) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: "lightblue",
    },
  });

export const CustomToolbar = (props: ToolbarProps) => {
  const goToBack = () => {
    props.onNavigate("PREV");
  };

  const goToNext = () => {
    props.onNavigate("NEXT");
  };

  const goToCurrent = () => {
    props.onNavigate("TODAY");
  };

  return (
    <div className="p-5 rbc-toolbar">
      <span className="!text-left rbc-toolbar-label font-medium text-md !pl-0">
        {props.label}
      </span>

      <span className="flex items-center gap-2 text-sm *:h-8">
        <button type="button" onClick={goToCurrent}>
          Today
        </button>
        <button type="button" onClick={goToBack}>
          <LuArrowLeft />
        </button>
        <button type="button" onClick={goToNext}>
          <LuArrowRight />
        </button>
      </span>
    </div>
  );
};

export function eventWrapper(props: any) {
  const data = props.event;

  const onEventClick = () => {
    window.location.href = data.path;
  };

  const customDiv = (
    <Tooltip>
      <TooltipTrigger
        className="w-full text-left rbc-event-content"
        onClick={onEventClick}
      >
        {data.name}
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent side="bottom" className="z-50 bg-white">
          <p className="text-sm font-semibold">{data.name}</p>
          <p className="text-xs text-gray-500">{data.description}</p>
          <p className="pt-2 text-sm">
            Schedule: {data.schedule_formatted}
          </p>
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
  const eventDiv = React.cloneElement(
    props.children.props.children,
    {},
    customDiv
  );

  const wrapper = React.cloneElement(props.children, {}, eventDiv);
  return <div>{wrapper}</div>;
}
