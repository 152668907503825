import React from "react";
import {
  SiFacebook,
  SiX,
  SiInstagram,
  SiPinterest,
  SiTiktok,
  SiMicrosoftbing,
  SiApple,
  SiGoogle,
  SiShopify,
  SiMessenger,
  SiSnapchat,
  SiLinktree,
  SiLinkedin,
  SiDuckduckgo,
  SiYoutube,
  SiSubstack,
} from "react-icons/si";
import { FaYahoo } from "react-icons/fa";
import {
  LuSmile,
  LuMail,
  LuAppWindow,
  LuSearch,
  LuMapPin,
  LuExternalLink,
  LuDiamond,
  LuBuilding,
  LuLink,
  LuMessageSquare,
  LuHeadphones,
  LuNetwork,
} from "react-icons/lu";

// Social icons are mainly from Simple Icons (https://simpleicons.org/)
// Other icons are from Lucide (https://lucide.dev/)
const iconMap = {
  "Paid Social": <LuSmile />,
  "Organic Social": <LuSmile />,
  Facebook: <SiFacebook />,
  Messenger: <SiMessenger />,
  Twitter: <SiX />,
  X: <SiX />,
  Snapchat: <SiSnapchat />,
  Linktree: <SiLinktree />,
  Linkedin: <SiLinkedin />,
  Instagram: <SiInstagram />,
  Pinterest: <SiPinterest />,
  Youtube: <SiYoutube />,
  TikTok: <SiTiktok />,
  Tiktok: <SiTiktok />,
  Substack: <SiSubstack />,
  Email: <LuMail />,
  Display: <LuAppWindow />,
  Google: <SiGoogle />,
  Bing: <SiMicrosoftbing />,
  Duckduckgo: <SiDuckduckgo />,
  "Search Yahoo": <FaYahoo />,
  "Yahoo": <FaYahoo />,
  "Paid Search": <LuSearch />,
  "Organic Search": <LuSearch />,
  Sms: <LuMessageSquare />,
  Ios: <SiApple />,
  Audio: <LuHeadphones />,
  "Cross Network": <LuNetwork />,
  "Shop App": <SiShopify />,
  "Shopify": <SiShopify />,
  Affiliate: <LuLink />,
  Search: <LuSearch />,
  Direct: <LuMapPin />,
  Referral: <LuExternalLink />,
  Internal: <LuBuilding />,
};

export const getIconForChannel = (channelName) => {
  return iconMap[channelName] || <LuDiamond />;
};
