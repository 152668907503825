import React from "react";
import { LuRefreshCw } from "react-icons/lu";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { cn } from "~/utils/tailwind";

import { Tooltip, TooltipContent, TooltipTrigger } from "~/components/tooltip";
import { CustomReport } from "../types";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

// 10 AM UTC and 10 PM UTC converted to local time
const refreshTimeLocal = {
  firstRun: dayjs.utc().hour(10).minute(0).local().format("h:mm A"),
  lastRun: dayjs.utc().hour(22).minute(0).local().format("h:mm A zzz"),
};

const refreshFrequency = `This report is automatically refreshed every 12 hours, at ${refreshTimeLocal.firstRun} and ${refreshTimeLocal.lastRun}.`;

interface RefreshButtonProps {
  report: CustomReport;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled: boolean;
}

export const RefreshButton = ({
  report,
  onClick,
  disabled = false,
}: RefreshButtonProps) => {
  return (
    <Tooltip>
      <TooltipTrigger
        className="block w-auto h-full p-2 btn btn-sm"
        onClick={onClick}
        disabled={disabled}
      >
        <LuRefreshCw className={cn("w-4 h-auto", disabled && "animate-spin")} />
      </TooltipTrigger>
      <TooltipContent
        className={cn(
          "flex flex-col gap-1 px-3 py-2 w-72",
          report.status === "RUNNING" && "hidden"
        )}
      >
        <h5 className="text-xs font-semibold text-gray-800">Refresh report</h5>
        <p className="w-full text-xs text-gray-500">
          <span>Last refreshed: </span>
          <span className="text-gray-800">
            {report.last_refreshed_at_formatted}
          </span>
        </p>

        <p className="w-full pt-4">{refreshFrequency}</p>
      </TooltipContent>
    </Tooltip>
  );
};
