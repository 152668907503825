import { z } from "zod";

export const editReportQuerySchema = z.object({
  store_id: z.string().uuid(),
  parent_id: z.string().uuid().optional(),
  report_id: z.string().uuid(),
  google_sheet_tab_name: z.string().min(1, "Google Sheet tab name is required"),
  query: z
    .string()
    .min(10, {
      message: "Report query must be at least 10 characters.",
    })
    .refine(
      (value) => {
        const storeId = window.location.pathname.split("/")[2];
        return value.toLowerCase().includes(`store_id = '${storeId}'`);
      },
      { message: "Report query must be scoped to this store." }
    ),
});

export type EditQueryFormData = z.infer<typeof editReportQuerySchema>;
