import { Controller } from "stimulus";
import { createRoot } from "react-dom/client";

import Switch from "../../components/contentToggles/switch";

// Connects to data-controller="contentToggles--switch"
export default class extends Controller {
  static targets = ["toggle"];
  static values = {
    url: String,
    initialState: Boolean,
  };

  connect() {
    this.state = this.initialStateValue;

    if (!this.root) {
      this.root = createRoot(this.toggleTarget);
    }

    this.root.render(
      <Switch defaultChecked={this.state} url={this.urlValue} />
    );
  }

  disconnect() {
    if (this.root) {
      this.root.unmount();
      this.root = null;
    }
  }
}
