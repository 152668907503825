import Slider, { SliderProps } from "rc-slider";

import { cn } from "~/utils/tailwind";

import styles from "./slider.module.css";

import "rc-slider/assets/index.css";

const FrequencySlider = (props: SliderProps) => {
  return (
    <div className="relative w-full h-14">
      <div className="px-4">
        <Slider
          {...props}
          className={cn(styles.frequencySlider, props.className)}
        />
      </div>
      <div className="absolute flex justify-between w-full mt-5 text-sm text-gray-600">
        <span>Low</span>
        <span>High</span>
      </div>
    </div>
  );
};

export default FrequencySlider;
