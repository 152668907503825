import React, { useMemo, useState } from "react";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

import { Chart, ChartProps } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  TimeScale,
  Point,
  LegendOptions,
  TooltipItem,
  ChartMeta,
} from "chart.js";
import { numberToCurrency, numberToHuman } from "../../utils/number-formatters";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  TimeScale
);

type Currency = string;

type Props = {
  currency: Currency;
  data: {
    identified_sessions: Point[];
    recovered_revenue: Point[];
    total_revenue: Point[];
  };
};

type LegendItemType = Omit<ChartMeta<"bar" | "line">, "hidden"> & {
  hidden: boolean | null;
};

const formatTooltipLabel = (
  context: TooltipItem<"bar">,
  currency: string,
  isRecoveredVisible: boolean
) => {
  const label = context.dataset.label ? context.dataset.label + ": " : "";
  let value = context.parsed.y;

  if (context.dataset.label === "Total Revenue" && isRecoveredVisible) {
    const recoveredRevenueValue = context.chart.data.datasets[2].data[
      context.dataIndex
    ] as Point;
    value += parseFloat(recoveredRevenueValue.y.toString());
  }

  return (
    label +
    (context.dataset.label !== "Identified Sessions"
      ? numberToCurrency(value, {
          currency,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          minimumIntegerDigits: 1,
          useGrouping: true,
        })
      : value.toLocaleString())
  );
};

const formatDataWithVisibility = (
  data: Props["data"],
  isRecoveredVisible: boolean
): ChartProps["data"] => {
  const adjustedTotalRevenue = data.total_revenue.map((point, index) => {
    const recoveredRevenue = data.recovered_revenue[index]?.y ?? 0;
    return {
      ...point,
      y: isRecoveredVisible ? point.y - recoveredRevenue : point.y,
    };
  });

  const adjustedRecoveredRevenue = data.recovered_revenue.map((point) => ({
    ...point,
    y: isRecoveredVisible ? point.y : 0,
  }));

  return {
    datasets: [
      {
        type: "line",
        label: "Identified Sessions",
        borderColor: "#C4B5FD",
        backgroundColor: "#C4B5FD",
        data: data.identified_sessions,
        yAxisID: "sessions",
      },
      {
        type: "bar",
        label: "Total Revenue",
        backgroundColor: "#DBEAFE",
        data: adjustedTotalRevenue,
        yAxisID: "revenue",
      },
      {
        type: "bar",
        label: "Recovered Revenue",
        backgroundColor: "#93C5FD",
        data: adjustedRecoveredRevenue,
        yAxisID: "revenue",
      },
    ],
  };
};

const options: ChartProps["options"] = {
  elements: {
    point: {
      borderWidth: 0,
      radius: 4,
    },
  },
  animation: false,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: "time",
      title: {
        display: true,
        text: "Date",
        padding: 8,
      },
      grid: {
        display: false,
      },
      time: {
        minUnit: "day",
        tooltipFormat: "MMMM Do YYYY",
        parser: (value) => {
          const date = value as string;
          const convertedDate = dayjs.utc(date).format("YYYY-MM-DD");

          // Workaround: The Day.js adapter allows a dayjs object or string
          // but the Chart.js type doesn't know about that.
          return convertedDate as unknown as number;
        },
      },
      stacked: true,
      suggestedMax: 4,
      ticks: {
        source: "data",
        autoSkip: true,
        align: "center",
        maxRotation: 0,
        maxTicksLimit: 8,
      },
    },
    revenue: {
      position: "left",
      title: {
        display: true,
        text: "Revenue",
        padding: 8,
      },
      grid: {
        display: false,
      },
      stacked: true,
      ticks: {
        maxTicksLimit: 8,
      },
    },
    sessions: {
      position: "right",
      title: {
        display: true,
        text: "Identified Sessions",
        padding: 8,
      },
      grid: {
        display: false,
      },
      ticks: {
        maxTicksLimit: 12,
      },
    },
  },
  plugins: {
    tooltip: {
      backgroundColor: "white",
      titleColor: "#1A202D",
      bodyColor: "#1A202D",
      borderColor: "#f1f5f9",
      borderWidth: 1,
      cornerRadius: 4,
      usePointStyle: true,
      boxPadding: 6,
      padding: 12,
      mode: "index",
      intersect: false,
    },
    legend: {
      position: "bottom",
      align: "start",
      labels: {
        padding: 24,
        textAlign: "left",
        usePointStyle: true,
        pointStyle: "circle",
      },
    },
  },
};

const RecoveredRevenueChart = ({ data, currency }: Props) => {
  const [isRecoveredVisible, setIsRecoveredVisible] = useState(true);

  const formattedData = useMemo(
    () => formatDataWithVisibility(data, isRecoveredVisible),
    [data, isRecoveredVisible]
  );

  const handleLegendClick: LegendOptions<"bar" | "line">["onClick"] = (
    e,
    legendItem,
    legend
  ) => {
    const index = legendItem.datasetIndex!;
    const chart = legend.chart;
    const meta: LegendItemType = chart.getDatasetMeta(index);
    meta.hidden =
      meta.hidden === null ? !chart.data.datasets[index].hidden : null;

    if (legendItem.text === "Recovered Revenue") {
      setIsRecoveredVisible(!isRecoveredVisible);
    } else {
      chart.update();
    }
  };

  const chartOptions = {
    ...options,
    scales: {
      ...options.scales,
      revenue: {
        ...options.scales?.revenue,
        ticks: {
          ...options.scales?.revenue?.ticks,
          callback: (value: number | string) =>
            numberToCurrency(parseFloat(value.toString()), { currency }),
        },
      },
      sessions: {
        ...options.scales?.sessions,
        ticks: {
          ...options.scales?.sessions?.ticks,
          callback: (value: number | string) =>
            numberToHuman(parseFloat(value.toString())),
        },
      },
    },
    plugins: {
      ...options.plugins,
      tooltip: {
        ...options.plugins?.tooltip,
        callbacks: {
          label: (context: TooltipItem<"bar">) =>
            formatTooltipLabel(context, currency, isRecoveredVisible),
        },
      },
      legend: {
        ...options.plugins?.legend,
        onClick: handleLegendClick,
      },
    },
  };

  return <Chart type="bar" data={formattedData} options={chartOptions} />;
};

export default RecoveredRevenueChart;
