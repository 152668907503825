import React from "react";
import { LuCheckCircle, LuAlertTriangle, LuStar } from "react-icons/lu";

import { cn } from "../../utils/tailwind";
import { numberToPercentage } from "../../utils/number-formatters";

import statusConfig from "./statusConfig.json";

type Status = "Needs attention" | "Good" | "Excellent";

function determineStatus(number: number): Status {
  const defaultStatus: Status = "Needs attention";

  const found = statusConfig.statuses.find(
    (status) => number >= status.min && number <= status.max
  );

  return found ? (found.status as Status) : defaultStatus;
}

function getIcon(rating: Status) {
  const size = 20;

  switch (rating) {
    case "Needs attention":
      return <LuAlertTriangle size={size} />;
    case "Good":
      return <LuCheckCircle size={size} />;
    case "Excellent":
      return <LuStar size={size} />;
    default:
      return <LuAlertTriangle size={size} />;
  }
}

function getColorClasses(rating: Status): string {
  switch (rating) {
    case "Needs attention":
      return "bg-orange-50 text-orange-700";
    case "Good":
      return "bg-green-50 text-green-700";
    case "Excellent":
      return "bg-blue-50 text-blue-700";
    default:
      return "bg-orange-50 text-orange-700";
  }
}

interface IdentificationStatusProps {
  value: number;
}

const Popover = ({ identificationRate }: { identificationRate: string }) => (
  <div className="hidden" data-popover-target="content">
    <div
      className="absolute left-0 z-10 flex flex-col gap-1 px-3 py-2 font-normal bg-white border border-gray-200 rounded shadow-lg bottom-12 w-72"
      data-popover-target="card"
    >
      <h5 className="text-base font-semibold text-gray-800">
        Identification Status
      </h5>
      <p className="pb-2 text-xs text-gray-500">
        Identification Status is a measure of known users visiting the site and
        its effect on revenue. Solve produces this by reviewing traffic patterns
        across our customers.
      </p>
      <div className="grid justify-between grid-cols-7 pt-1 text-sm text-gray-800 border-t border-gray-200">
        <p className="col-span-4 font-semibold">Identification Rate</p>
        <p className="col-span-3 text-right">{identificationRate}</p>
      </div>
    </div>
  </div>
);

const IdentificationStatus = ({ value }: IdentificationStatusProps) => {
  const rating = determineStatus(value);
  const colorClasses = getColorClasses(rating);
  const identificationRate = numberToPercentage(value ?? 0, 2);

  return (
    <div className="relative min-w-52" data-controller="popover">
      <div
        className={cn(
          "flex flex-row items-center px-3 py-1.5 rounded gap-3 w-full",
          colorClasses
        )}
        data-action="mouseenter->popover#show mouseleave->popover#hide"
      >
        {getIcon(rating)}
        <p className="text-base font-medium">{rating}</p>
      </div>
      <Popover identificationRate={identificationRate} />
    </div>
  );
};

export default IdentificationStatus;
