import { create } from "zustand";
import { CustomReport } from "../../types";
import { createCombinedReports } from "./helpers";

interface ReportState {
  report: CustomReport;
  combinedReports: CustomReport[];
  isAllRefreshable: boolean;
  disabledQueries: Record<CustomReport["id"], boolean>;
  setReport: (report: CustomReport) => void;
  updateReportData: (data: CustomReport) => void;
  toggleIndividual: (id: CustomReport["id"]) => void;
}

export const useReportManagement = create<ReportState>((set, get) => ({
  report: {} as CustomReport,
  combinedReports: [],
  isAllRefreshable: true,
  disabledQueries: {},

  setReport: (report) => {
    const combinedReports = createCombinedReports(report);
    const disabledQueries = Object.fromEntries(
      combinedReports.map((query) => [query.id, query.status === "RUNNING"])
    );
    const isAllRefreshable = !Object.values(disabledQueries).every(
      (disabled) => disabled
    );

    set({ report, combinedReports, isAllRefreshable, disabledQueries });
  },

  updateReportData: (data) => {
    set((state) => {
      let updatedReport: CustomReport;
      if (state.report.id === data.id) {
        updatedReport = { ...state.report, ...data };
      } else {
        updatedReport = {
          ...state.report,
          child_reports: state.report.child_reports.map((childReport) =>
            childReport.id === data.id
              ? { ...childReport, ...data }
              : childReport
          ),
        };
      }

      const combinedReports = createCombinedReports(updatedReport);
      const disabledQueries = {
        ...state.disabledQueries,
        [data.id]: data.status === "RUNNING",
      };
      const isAllRefreshable = !Object.values(disabledQueries).every(
        (disabled) => disabled
      );

      return {
        report: updatedReport,
        combinedReports,
        isAllRefreshable,
        disabledQueries,
      };
    });
  },

  toggleIndividual: (id) => {
    set((state) => {
      const currentState = state.disabledQueries[id];
      const disabledQueries = {
        ...state.disabledQueries,
        [id]: !currentState,
      };
      const isAllRefreshable = !Object.values(disabledQueries).every(
        (disabled) => disabled
      );

      return {
        disabledQueries,
        isAllRefreshable,
      };
    });
  },
}));
