export const numberToPercentage = (number, precision = 2) => {
  const percentage = number.toFixed(precision);
  return `${percentage}%`;
};

export const numberWithDelimiter = (number) => {
  const formatter = new Intl.NumberFormat("en-US");
  return formatter.format(number);
};

export const numberToHuman = (
  number,
  optionsOverride?: Intl.NumberFormatOptions
) => {
  const options: Intl.NumberFormatOptions = {
    notation: "compact",
    style: "decimal",
    maximumSignificantDigits: !optionsOverride?.notation ? 3 : undefined,
    maximumFractionDigits: 2,
    ...optionsOverride,
  };

  const formatter = new Intl.NumberFormat("en", options);
  return formatter.format(number);
};

export const numberToCurrency = (
  number,
  optionsOverride?: Intl.NumberFormatOptions
) => {
  const options: Intl.NumberFormatOptions = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    ...(optionsOverride?.notation === "compact"
      ? { maximumSignificantDigits: 3 }
      : {}),
    ...(number < 1000
      ? { maximumSignificantDigits: 5 }
      : { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
    currencyDisplay: "symbol",
    ...optionsOverride,
  };

  const formatter = new Intl.NumberFormat("en-US", options);
  return formatter.format(number);
};
