import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content", "icon"];

  toggle() {
    this.contentTarget.classList.toggle("hidden");
    this.contentTarget.classList.toggle("flex");
    this.iconTarget.classList.toggle("rotate-180");
  }
}
