import { Controller } from "@hotwired/stimulus";
import { createRoot } from "react-dom/client";

import Frequency from "../../components/automations/frequency";
import { demoData } from "../../components/automations/frequency/demoData";
import { getFrequency } from "../../components/automations/frequency/data";

// Connects to data-controller="automations--frequency"
export default class extends Controller {
  static targets = ["frequency"];

  static values = {
    url: String,
    storeId: String
  };

  async connect() {
    this.validateInputs();

    getFrequency(this.storeIdValue)
      .then((frequency_string) => {
        return parseInt(frequency_string);
      })
      .then((frequency) => {
        // TODO: Add an initialValues target to fetch data from the API
        const initialValues = demoData(frequency);
        createRoot(this.frequencyTarget).render(
          <Frequency initialValues={initialValues} />
        );
      });
  }

  validateInputs() {
    if (!this.frequencyTarget) {
      throw new Error(
        `Frequency target for data-controller="automations--frequency" must be set`
      );
    }
  }
}
