export const labels = {
  revenue: {
    title: "Revenue",
  },
  retention: {
    title: "Retention",
  },
  low_value_churn: {
    title: "Low Value Churn",
  },
  high_value_churn: {
    title: "High Value Churn",
  },
} as const;
