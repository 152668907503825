import { Controller } from "@hotwired/stimulus";

const MAX_FILE_SIZE_MB = 50;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
// The error message is defined here so we can't forget to change it if we change the
// file size limit
const MAX_SIZE_EXCEEDED_MESSAGE = `The size of one of the files you are trying to upload is too large. Please upload files with a total size of less than ${MAX_FILE_SIZE_MB}MB.`;

export default class extends Controller {
  static targets = [
    "fileInput",
    "formError",
    "formErrorContainer",
    "submitButton"
  ];

  connect() {
    // disable the import button until the user selects a file
    this.submitButtonTarget.disabled = true;
    // the input field gets its id from the `:files` arg in the template
    this.fileInputTarget.addEventListener("change", (change, _event) => {
      const fileList = change.target.files;
      // for some reason the fileList is not iterable, so we have to loop over it old school style
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].size > MAX_FILE_SIZE_BYTES) {
          this.showFileSizeError();
          return;
        }
      }
      // if none of the files are too large, get rid of the error message in case it was shown before
      this.clearFileSizeError();
    });
  }

  showFileSizeError() {
    // populate and show error box
    this.formErrorTarget.innerText = MAX_SIZE_EXCEEDED_MESSAGE;
    this.formErrorContainerTarget.classList.remove("hidden");
    // disable submit button
    this.submitButtonTarget.disabled = true;
  }

  clearFileSizeError() {
    // hide error box
    this.formErrorContainerTarget.classList.add("hidden");
    // enable submit button
    this.submitButtonTarget.disabled = false;
  }

  activateUploadIndicator(event) {
    this.submitButtonTarget.value = "Uploading...";
  }

  reportUploadProgress(event) {
    console.log("CSV import upload progress", event);
  }
}
