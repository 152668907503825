import { ControllerRenderProps } from "react-hook-form";
import { ControlsFormData } from ".";
import {
  DateControl,
  DateRangeControl,
  DEFAULT_DATE_RANGE,
  DEFAULT_DATE_VALUE,
  DEFAULT_LOOKBACK_VALUE,
  LookbackPeriodControl,
} from "./fields";
import { VariableType } from "./schema";

export const renderControl = (
  control: VariableType,
  field: ControllerRenderProps<ControlsFormData, string>
): React.ReactElement => {
  switch (control.variable_type) {
    case "DATE":
      return <DateControl field={field} />;
    case "DATE_RANGE":
      return <DateRangeControl field={field} />;
    case "LOOKBACK_PERIOD":
      return <LookbackPeriodControl field={field} />;
    default:
      return <></>;
  }
};

export const getDefaultValues = (
  controls: VariableType[]
): ControlsFormData => {
  if (!controls) return {};

  return controls.reduce((acc, control) => {
    switch (control.variable_type) {
      case "DATE":
        acc[control.id] =
          control.value && Object.keys(control.value).length > 0
            ? control.value
            : DEFAULT_DATE_VALUE;
        break;
      case "DATE_RANGE":
        acc[control.id] =
          control.value && Object.keys(control.value).length > 0
            ? control.value
            : DEFAULT_DATE_RANGE;
        break;
      case "LOOKBACK_PERIOD":
        acc[control.id] =
          control.value && Object.keys(control.value).length > 0
            ? control.value
            : DEFAULT_LOOKBACK_VALUE;
        break;
      default:
        acc[control.id] = control.value;
    }
    return acc;
  }, {} as ControlsFormData);
};
