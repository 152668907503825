import React from "react";
import { Chart, ChartProps } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title);

type BarChartProps = {
  maxValue: number;
  chartData: {
    returningRevenue: number;
    newRevenue: number;
  };
};

const BarChart = ({ chartData, maxValue }: BarChartProps) => {
  const data: ChartProps["data"] = {
    labels: [""],
    datasets: [
      {
        label: "New",
        data: [chartData.newRevenue],
        borderColor: "#ffffff",
        backgroundColor: "#93c5fd",
        hoverBackgroundColor: "#93c5fd",
      },
      {
        label: "Returning",
        data: [chartData.returningRevenue],
        borderColor: "#ffffff",
        backgroundColor: "#d8b4fe",
        hoverBackgroundColor: "#d8b4fe",
      },
    ],
  };

  const options: ChartProps["options"] = {
    indexAxis: "y",
    elements: {
      bar: {
        borderRadius: 2,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
        beginAtZero: true,
        max: maxValue,
      },
      y: {
        display: false,
      },
    },
    animation: {
      duration: 0,
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  return <Chart type="bar" options={options} data={data} />;
};

export default BarChart;
