import { Controller } from "@hotwired/stimulus";
import { createRoot } from "react-dom/client";

import ProductsTable from "../../components/automations/content/table";

// Connects to data-controller="automations--content"
export default class extends Controller {
  static targets = ["table"];

  static values = {
    content: Array
  };

  connect() {
    this.validateInputs();

    createRoot(this.element).render(<ProductsTable data={this.contentValue} />);
  }

  validateInputs() {
    if (!this.tableTarget) {
      throw new Error(
        `Table target for data-controller="automations--content" must be set`
      );
    }

    if (!this.contentValue) {
      throw new Error(
        `initialValues value for data-controller="automations--content" must be set`
      );
    }
  }
}
