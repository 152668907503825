import { getGoogleSheetUrl } from "../data";
import { SiGooglesheets } from "react-icons/si";
import { LuAlertOctagon } from "react-icons/lu";

import { CustomReport } from "../types";

type GoogleSheetLinkProps = {
  url: CustomReport["google_sheet_url"];
};

const ButtonContent = () => (
  <>
    <SiGooglesheets className="text-green-700 size-4" />
    View Google Sheet
  </>
);

const NoUrlWarning = () => (
  <p className="flex flex-row items-center gap-2 text-red-500">
    <LuAlertOctagon className="text-red-700" /> No Google Sheet URL added
  </p>
);

const DisabledButton = () => (
  <button
    className="flex items-center gap-1.5 align-middle text-sm btn w-fit h-full cursor-not-allowed opacity-40"
    disabled
  >
    <ButtonContent />
  </button>
);

const ActiveLink = ({ url }: { url: string }) => (
  <a
    href={getGoogleSheetUrl(url)}
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center gap-1.5 align-middle text-sm btn w-fit h-full"
  >
    <ButtonContent />
  </a>
);

export const GoogleSheetLink = ({ url }: GoogleSheetLinkProps) => {
  if (!url) {
    return (
      <>
        <DisabledButton />
        <NoUrlWarning />
      </>
    );
  }

  return <ActiveLink url={url} />;
};
