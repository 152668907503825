import { pluralize } from "~/utils/string-formatters";

import { CustomReport } from "../types";

const FormErrors = ({ errors }: { errors: CustomReport["errors"] }) => {
  if (!errors || Object.entries(errors).length === 0) return;

  const errorsArray: string[] = Object.entries(errors).map(
    ([field, messages]) => {
      const message = messages.join(", ");
      return `${field} ${message}`;
    }
  );

  return (
    <div className="px-3 py-2 mt-3 font-medium text-red-500 rounded-lg bg-red-50">
      <h2>
        {pluralize(errorsArray.length, "error")} prohibited this query from
        being saved:
      </h2>

      <ul>
        {errorsArray.map((error: string) => (
          <li>{error}</li>
        ))}
      </ul>
    </div>
  );
};

export default FormErrors;
