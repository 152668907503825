import React, { useState } from "react";
import { LuLoader } from "react-icons/lu";

import { cn } from "~/utils/tailwind";

import { CustomReport, ReportQuery } from "../../types";
import { editReport, addReport, deleteReport } from "../../data";
import FormErrors from "../formErrors";

interface Props {
  reportQuery: ReportQuery;
  parentReport: CustomReport | null;
  onCancel: () => void;
  onSubmit: (reportQuery: ReportQuery) => void;
  onDelete: (reportQuery: ReportQuery) => void;
}

const EditQuery = ({
  reportQuery: initialReportQuery,
  parentReport,
  onCancel,
  onSubmit,
  onDelete,
}: Props) => {
  const [reportQuery, setReportQuery] =
    useState<ReportQuery>(initialReportQuery);
  const [isNewQuery] = useState(!reportQuery.id);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [formState, setFormState] = useState({
    query: reportQuery?.query || "",
    google_sheet_tab_name: reportQuery?.google_sheet_tab_name || "",
    errors: undefined,
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this report?")) {
      if (!reportQuery.id) {
        console.log("Must have an id to delete");
        return;
      }
      setIsDeleting(true);
      const response = await deleteReport({
        storeId: reportQuery.store_id,
        reportId: reportQuery.id,
      });
      setIsDeleting(false);

      if (response.ok) {
        onDelete(reportQuery);
      } else {
        response.json().then((data) => {
          console.log("Could not delete report", data);
        });
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsUpdating(true);
    const response = await saveReport();
    setIsUpdating(false);

    if (response.ok) {
      if (isNewQuery) {
        // Update report with response data, change component to saved state
        response.json().then((data) => {
          const savedReport = {
            store_id: data.store_id,
            id: data.id,
            query: data.query,
            google_sheet_tab_name: data.google_sheet_tab,
          };
          onSubmit(savedReport);
        });
      }
    } else {
      response.json().then((data) => {
        setFormState((prevState) => ({
          ...prevState,
          errors: data,
        }));
      });
    }
  };

  const saveReport = async (): Promise<Response> => {
    setIsUpdating(true);

    const formData = new FormData();
    formData.append("[custom_report][query]", formState.query);
    formData.append(
      "[custom_report][google_sheet_tab]",
      formState.google_sheet_tab_name
    );

    let response;
    if (parentReport && isNewQuery) {
      formData.append("[custom_report][parent_id]", parentReport.id);
      // Add new child report
      response = await addReport({
        storeId: parentReport.store_id,
        formData,
      });
    } else {
      response = await editReport({
        storeId: reportQuery.store_id,
        reportId: reportQuery.id ?? "",
        formData: formData,
      });
    }

    setIsUpdating(false);
    return response;
  };

  return (
    <form onSubmit={handleSubmit} className="relative flex flex-col gap-4">
      <FormErrors errors={formState.errors} />

      <div>
        <label htmlFor="google_sheet_tab_name" className="field-label">
          Google Sheet tab name
        </label>
        <input
          type="text"
          name="google_sheet_tab_name"
          id="google_sheet_tab_name"
          onChange={handleInputChange}
          defaultValue={reportQuery.google_sheet_tab_name}
          className="w-full field-input"
          data-1p-ignore="true"
          required
        />
      </div>

      <div>
        <label htmlFor="query" className="field-label">
          Query
        </label>
        <textarea
          name="query"
          id="query"
          onChange={handleInputChange}
          defaultValue={reportQuery.query}
          className="field-input font-mono max-h-96 min-h-[256px] w-full"
          data-1p-ignore="true"
          required
        />
      </div>

      <div
        className={cn(
          "flex flex-row justify-end",
          !isNewQuery && parentReport && "justify-between"
        )}
      >
        {!isNewQuery && parentReport && (
          <button
            type="button"
            className="flex items-center gap-2 px-5 btn-danger w-fit"
            onClick={handleDelete}
            disabled={isDeleting}
          >
            {isDeleting ? <LuLoader className="animate-spin" /> : null}
            {isDeleting ? "Deleting..." : "Delete"}
          </button>
        )}

        <div className="flex flex-row items-center gap-3">
          {isNewQuery && (
            <button
              type="button"
              className="flex items-center gap-2 px-5 btn-secondary w-fit"
              onClick={onCancel}
            >
              Cancel
            </button>
          )}

          <button
            type="submit"
            className="flex items-center gap-2 px-5 btn-primary w-fit"
            disabled={isUpdating}
          >
            {isUpdating ? <LuLoader className="animate-spin" /> : null}
            {isUpdating
              ? isNewQuery
                ? "Creating..."
                : "Updating..."
              : isNewQuery
              ? "Create"
              : "Update"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditQuery;
