import React, { useMemo } from "react";
import {
  useReactTable,
  getExpandedRowModel,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
  SortingState,
} from "@tanstack/react-table";
import { LuChevronDown, LuChevronUp } from "react-icons/lu";

import { cn } from "~/utils/tailwind";

import { TooltipProvider } from "~/components/tooltip";

export type Product = {
  type: string;
  name: string;
  image_url: string;
  visibility: "hidden" | "default";
};

type TableProps = {
  data: Product[];
};

import productColumns from "./columns";

const ProductsTable = ({ data }: TableProps) => {
  const columns = useMemo(() => productColumns(), [data]);
  const [expanded, setExpanded] = React.useState({});
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: "name",
      desc: false,
    },
  ]);

  const table = useReactTable({
    columns,
    data,
    state: {
      expanded,
      sorting,
    },
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });
  const rows = table.getRowModel().rows;

  if (data.length === 0) {
    return (
      <div className="w-full bg-white h-52">
        <p className="flex items-center justify-center h-full text-gray-500">
          No products data available
        </p>
      </div>
    );
  }

  return (
    <TooltipProvider>
      <table className="w-full">
        <thead className="border-b bg-gray-50">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="px-6 py-4 text-sm font-normal text-left text-gray-500"
                  style={{
                    width:
                      header.getSize() === Number.MAX_SAFE_INTEGER
                        ? "auto"
                        : `${header.getSize()}px`,
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      className={cn(
                        header.column.getCanSort() &&
                          "flex flex-row items-center gap-1 cursor-pointer select-none relative"
                      )}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      <span
                        className={cn(
                          "absolute text-gray-800",
                          header.id == "name" || header.id == "visibility"
                            ? "left-[60px]"
                            : "-right-5"
                        )}
                      >
                        {{
                          asc: <LuChevronUp />,
                          desc: <LuChevronDown />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </span>
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white">
          {rows.map((row) => {
            return (
              <tr
                key={row.id}
                className="border-gray-200 border-y last:border-b-0"
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      style={{
                        width:
                          cell.column.getSize() === Number.MAX_SAFE_INTEGER
                            ? "auto"
                            : `${cell.column.getSize()}px`,
                      }}
                      key={cell.id}
                      className={cn("h-full px-6 py-0 my-0 whitespace-nowrap")}
                    >
                      <div className={cn("h-full py-4")}>
                        <div className="flex items-center h-8">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </div>
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </TooltipProvider>
  );
};

export default ProductsTable;
