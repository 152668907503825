import React from "react";
import { LuChevronDown, LuChevronUp } from "react-icons/lu";

import { FrequencyState } from ".";

const Difference = ({
  difference,
  difference_formatted,
  difference_state,
}: Partial<FrequencyState>) => {
  if (!difference) return null;

  const isPositive = difference > 0;
  const normalColor = isPositive ? "text-green-500" : "text-red-500";
  const invertedColor = isPositive ? "text-red-500" : "text-green-500";
  const iconColor = difference_state === "normal" ? normalColor : invertedColor;

  return (
    <div className="flex items-center gap-1">
      {isPositive ? (
        <LuChevronUp className={`size-4 ${iconColor}`} />
      ) : (
        <LuChevronDown className={`size-4 ${iconColor}`} />
      )}
      <p className="text-base text-gray-600">{difference_formatted}</p>
    </div>
  );
};

export default Difference;
