import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/components/dialog";

import { type EditQueryFormData, editReportQuerySchema } from "../../schemas";
import { editReport } from "../../data";
import { useReportManagement } from "../../view/hooks/useReportManagement";
import { getUpdatedReport } from "./utils";
import { FieldError, GoogleSheetTabInput, QueryInput } from "./formFields";

interface Props {
  defaultValues: EditQueryFormData;
  onOpenChange: (open: boolean) => void;
  openDialog: boolean;
}

export const EditDataDialog = ({
  defaultValues,
  onOpenChange,
  openDialog,
}: Props) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const { report, updateReportData } = useReportManagement();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<EditQueryFormData>({
    resolver: zodResolver(editReportQuerySchema),
    mode: "onChange",
    defaultValues,
  });

  const onSubmit = async (formData: EditQueryFormData) => {
    setIsUpdating(true);
    try {
      const requestFormData = new FormData();
      requestFormData.append("[custom_report][query]", formData.query);
      requestFormData.append(
        "[custom_report][google_sheet_tab]",
        formData.google_sheet_tab_name
      );

      const response = await editReport({
        storeId: formData.store_id,
        reportId: formData.report_id,
        formData: requestFormData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to update report");
      }

      // Update the report data
      const updatedReport = getUpdatedReport(report, formData);
      updateReportData(updatedReport);

      onOpenChange(false);
    } catch (error) {
      console.error("Error updating report:", error);
      setError("root", {
        message:
          error instanceof Error ? error.message : "Please try again later",
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Dialog open={openDialog} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit data</DialogTitle>
          <DialogDescription>
            Change your Google Sheet Tab name or query.
          </DialogDescription>
        </DialogHeader>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-8 @container"
        >
          <GoogleSheetTabInput<EditQueryFormData>
            register={register}
            errors={errors}
            name="google_sheet_tab_name"
            label="Google Sheet tab name"
          />
          <QueryInput<EditQueryFormData>
            register={register}
            errors={errors}
            name="query"
            label="Query"
          />
          <FieldError error={errors.root?.message} />

          <DialogFooter>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => onOpenChange(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isUpdating}
            >
              {isUpdating ? "Updating..." : "Submit"}
            </button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
