import { useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/components/dialog";

import { deleteReport } from "../../data";
import { useReportManagement } from "../../view/hooks/useReportManagement";

interface Props {
  values: {
    store_id: string;
    report_id: string;
    query_name: string;
  };
  onOpenChange: (open: boolean) => void;
  openDialog: boolean;
}

export const DeleteDataDialog = ({
  values,
  onOpenChange,
  openDialog,
}: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { report, updateReportData } = useReportManagement();

  const onSubmit = async () => {
    if (report.id === values.report_id) {
      console.warn("Cannot delete the main report with the dialog.");
      return;
    }

    setIsDeleting(true);
    try {
      const response = await deleteReport({
        storeId: values.store_id,
        reportId: values.report_id,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete report");
      }

      // Remove the report from child_reports state
      const updatedReport = {
        ...report,
        child_reports: report.child_reports.filter(
          (childReport) => childReport.id !== values.report_id
        ),
      };
      updateReportData(updatedReport);

      onOpenChange(false);
    } catch (error) {
      console.error("Error deleting report:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Dialog open={openDialog} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogDescription>
            This action cannot be undone. This will permanently delete the
            following query with the Google Sheet tab name:
          </DialogDescription>
        </DialogHeader>
        <div className="p-4 rounded text-gray-800 font-medium bg-gray-50 max-w-full overflow-auto">
          {values.query_name}
        </div>
        <DialogFooter>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => onOpenChange(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            disabled={isDeleting}
            onClick={onSubmit}
          >
            {isDeleting ? "Deleting..." : "Continue"}
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
