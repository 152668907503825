import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["inputField", "timePeriodField"]
  static values = {
    min: Object,
    max: Object
  }

  connect() {
    this.updateInputConstraints();
  }

  updateInputConstraints() {
    const timeFrame = this.timePeriodFieldTarget.value;
    const min = this.minValue[timeFrame] || this.inputFieldTarget.getAttribute('min');
    const max = this.maxValue[timeFrame] || this.inputFieldTarget.getAttribute('max');

    this.inputFieldTarget.min = min;
    this.inputFieldTarget.max = max;
  }
}
