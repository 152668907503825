import React, { useState } from "react";
import { LuGitCommit, LuClock, LuGlobe, LuCopy, LuCheck } from "react-icons/lu";
import { Badge } from "./badge";
import { cn } from "~/utils/tailwind";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./tooltip";
import TimeAgo from "./time-ago";
import dayjs from "dayjs";

const getEnvironmentColor = (env: string) => {
  switch (env.toLowerCase()) {
    case "production":
      return "bg-green-500 text-white";
    case "sandbox":
      return "bg-yellow-500 text-white";
    case "playground":
      return "bg-red-500 text-white";
    default:
      return "bg-transparent";
  }
};

type VersionInfoProps = {
  environment: string;
  commit_hash?: string;
  deploy_time?: string;
};

export default function VersionInfo({
  environment,
  commit_hash,
  deploy_time,
}: VersionInfoProps) {
  const [copied, setCopied] = useState(false);

  const copyEnvironmentInfo = () => {
    const info = `Environment: ${environment}
Commit: ${commit_hash ? commit_hash : "No commit hash available"}
Deployed: ${deploy_time ? deploy_time : "No deployment time available"}`;
    navigator.clipboard.writeText(info).then(() => {
      setCopied(true);

      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="text-gray-800">
      <TooltipProvider>
        <h3 className="text-sm font-semibold mb-4 px-4 pt-4">
          Version Information
        </h3>
        <div className="space-y-2">
          <div className="flex items-center justify-between px-4">
            <div className="flex items-center">
              <LuGlobe className="mr-2 size-4 text-gray-500" />
              <span>Environment:</span>
            </div>
            <Badge
              variant="outline"
              className={cn(getEnvironmentColor(environment))}
            >
              {environment}
            </Badge>
          </div>
          <div className="flex items-center justify-between px-4">
            <div className="flex items-center">
              <LuGitCommit className="mr-2 size-4 text-gray-500" />
              <span>Commit:</span>
            </div>
            {commit_hash ? (
              <a
                href={`https://github.com/solvedata/resolve/commit/${commit_hash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <code className="rounded bg-gray-200 px-2 py-1 text-xs">
                  {commit_hash.slice(0, 7)}
                </code>
              </a>
            ) : (
              <span className="text-xs text-gray-500">N/A</span>
            )}
          </div>
          <div className="flex items-center justify-between px-4">
            <div className="flex items-center">
              <LuClock className="mr-2 size-4 text-gray-500" />
              <span>Deployed:</span>
            </div>
            <Tooltip>
              <TooltipTrigger
                className={cn(
                  "text-xs text-gray-500 capitalize",
                  !deploy_time && "cursor-text"
                )}
              >
                {deploy_time ? <TimeAgo timestamp={deploy_time} /> : "N/A"}
              </TooltipTrigger>
              <TooltipContent className={cn({ hidden: !deploy_time })}>
                {dayjs(deploy_time).format("MMM D, YYYY h:mm A")}
              </TooltipContent>
            </Tooltip>
          </div>
          <div
            className="flex items-center border-t px-4 !mt-4 text-sm py-2.5 cursor-pointer hover:bg-gray-50"
            onClick={copyEnvironmentInfo}
          >
            {copied ? (
              <LuCheck className="mr-2 size-4 text-green-500" />
            ) : (
              <LuCopy className="mr-2 size-4 text-gray-500" />
            )}
            <span className={cn(copied ? "text-green-500" : "text-inherit")}>
              {copied ? "Copied" : "Copy Info"}
            </span>
          </div>
        </div>
      </TooltipProvider>
    </div>
  );
}
