import {
  UseFormRegister,
  FieldErrors,
  Path,
  FieldValues,
} from "react-hook-form";

export const FieldError = ({ error }: { error?: string }) => {
  if (!error) return null;
  return <p className="text-sm font-medium text-red-500 pt-1">{error}</p>;
};

interface InputProps<T extends FieldValues> {
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  name: Path<T>;
  label: string;
  placeholder?: string;
}

export const GoogleSheetTabInput = <T extends FieldValues>({
  register,
  errors,
  name,
  label,
  placeholder = "Sheet1",
}: InputProps<T>) => (
  <div>
    <label className="field-label">{label}</label>
    <input
      placeholder={placeholder}
      className="w-full field-input"
      {...register(name)}
      data-1p-ignore
    />
    <p className="text-xs text-gray-500">
      The Google Sheet tab name where the data is located.
    </p>
    <FieldError error={errors[name]?.message as string} />
  </div>
);

export const QueryInput = <T extends FieldValues>({
  register,
  errors,
  name,
  label,
}: InputProps<T>) => (
  <div>
    <label className="field-label">{label}</label>
    <textarea
      {...register(name)}
      className="field-input font-mono max-h-96 min-h-[256px] w-full"
      data-1p-ignore
    />
    <FieldError error={errors[name]?.message as string} />
  </div>
);
