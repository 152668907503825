import { useState } from "react";

import { LuInfo } from "react-icons/lu";
import FrequencySlider from "./slider";
import { getFrequency, getFrequencyPreview, updateFrequency } from "./data";
import { labels } from "./labels";
import Difference from "./difference";
import { getStoreId } from "../utils";

export type FrequencyState = {
  formatted_value: string;
  difference: number;
  difference_formatted: string;
  difference_state: "normal" | "inverted";
};

interface Props {
  initialValues: {
    frequency: number;
    revenue: FrequencyState;
    retention: FrequencyState;
    low_value_churn: FrequencyState;
    high_value_churn: FrequencyState;
  };
}

const Frequency = ({ initialValues }: Props) => {
  const [sliderValue, setSliderValue] = useState(initialValues.frequency);
  const [currentValues, setCurrentValues] = useState(initialValues);
  const [previewValues, setPreviewValues] = useState(initialValues);
  const [isDirty, setIsDirty] = useState(false);
  const [isLoadingMetrics, setIsLoadingMetrics] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const storeId = getStoreId() ?? "";

  const updateFrequencyPreview = async (value: number | number[]) => {
    const frequencyValue = Array.isArray(value) ? value[0] : value;

    if (frequencyValue === parseInt(await getFrequency(storeId))) {
      setIsDirty(false);
    } else {
      setIsDirty(true);
    }
    setIsLoadingMetrics(true);
    try {
      const data = await getFrequencyPreview(storeId, frequencyValue);
      setPreviewValues(data);
    } catch (error) {
    } finally {
      setIsLoadingMetrics(false);
    }
  };

  const resetFrequency = () => {
    setIsDirty(false);
    setSliderValue(currentValues.frequency);
    setPreviewValues(currentValues);
  };

  const saveFrequency = async () => {
    setIsSaving(true);
    try {
      // we need to pass frequency as a string from the  backend
      const data = await updateFrequency(storeId, previewValues.frequency.toString());
      setCurrentValues(data);
    } catch (error) {
      console.error("Update failed:", error);
    } finally {
      setIsSaving(false);
      setIsDirty(false);
    }
  };

  const frequency = previewValues.frequency;
  const metrics = [
    Object.assign(previewValues.revenue, labels.revenue),
    Object.assign(previewValues.retention, labels.retention),
    Object.assign(previewValues.low_value_churn, labels.low_value_churn),
    Object.assign(previewValues.high_value_churn, labels.high_value_churn)
  ];

  return (
    <>
      <div className="grid w-full grid-cols-1 gap-3 md:grid-cols-2">
        {metrics.map((item, index) => (
          <div
            key={index}
            className="flex flex-col justify-between gap-2 p-3 border rounded"
          >
            <div className="flex flex-col gap-2">
              <p className="text-sm font-medium text-gray-600">{item.title}</p>
            </div>
            <div className="flex items-center justify-between gap-1">
              <p className="text-lg font-medium">{item.formatted_value}</p>

              {item.difference !== 0 && (
                <Difference
                  difference={item.difference}
                  difference_formatted={item.difference_formatted}
                  difference_state={item.difference_state}
                />
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="flex flex-col gap-4 pt-5">
        <p className="font-medium text-gray-600">Frequency adjustment</p>

        <FrequencySlider
          className="w-full pb-4"
          defaultValue={[frequency]}
          min={0}
          max={4}
          step={1}
          dots
          value={[sliderValue]}
          onChange={(value) =>
            setSliderValue(Array.isArray(value) ? value[0] : value)
          }
          onChangeComplete={updateFrequencyPreview}
          disabled={isLoadingMetrics || isSaving}
        />

        <div className="flex flex-row justify-between pt-4">
          <div className="flex flex-row items-center gap-2 text-gray-500">
            <LuInfo /> <p>Frequency change compared to the last 7 days</p>
          </div>
          <div className="flex gap-2">
            <button
              className="border-0 btn"
              onClick={resetFrequency}
              disabled={!isDirty}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={saveFrequency}
              disabled={isSaving || !isDirty}
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Frequency;
