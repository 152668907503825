export const getStoreId = () => {
  const { pathname } = location;

  const pathSegments = pathname.split("/");

  // Find the index of 'stores' and get the next segment as storeId
  const storeIndex = pathSegments.indexOf("stores");
  const storeId =
    storeIndex !== -1 && pathSegments.length > storeIndex + 1
      ? pathSegments[storeIndex + 1]
      : null;

  return storeId;
};
