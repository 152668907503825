import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select"];
  static values = {
    default: String,
    url: String,
  };

  async connect() {
    this.validateInputs();

    const url = new URL(this.urlValue, window.location.href);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("currency");
    url.search = new URLSearchParams(searchParams);

    const response = await fetch(url);
    const currencies = await response.json();

    this.populateSelect(currencies);
    this.setSelectedCurrency();
  }

  validateInputs() {
    if (!this.defaultValue) {
      throw new Error(
        `Default currency value for data-controller="currency-select" must be set`
      );
    }

    if (!this.urlValue.endsWith(".json")) {
      throw new Error(
        `Invalid URL for data-controller="currency-select": must end with .json`
      );
    }
  }

  populateSelect(currencies) {
    const options = currencies.map(
      (currency) => `<option value="${currency}">${currency}</option>`
    );

    // the default currency will be selected if it isn't in the list or the list is empty
    if (!currencies.includes(this.defaultValue) || currencies.length === 0) {
      options.unshift(
        `<option value="${this.defaultValue}">${this.defaultValue}</option>`
      );
    }
    this.selectTarget.innerHTML = options.join("");
  }

  setSelectedCurrency() {
    const currencyParam = new URLSearchParams(window.location.search).get(
      "currency"
    );

    if (!!currencyParam) {
      this.selectTarget.value = currencyParam;
    } else {
      this.selectTarget.value = this.defaultValue;
    }
  }

  handleChange(event) {
    const currency = event.target.value;

    const url = new URL(window.location.href);
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.set("currency", currency);
    url.search = urlParams;

    window.location.href = url.toString();
  }
}
