import { Controller } from "@hotwired/stimulus";

import React from "react";
import { createRoot } from "react-dom/client";

import Chart from "../components/charts/recoveredRevenue";
import IdentificationStatus from "../components/identificationStatus/status";

import { numberToHuman } from "../utils/number-formatters";

// Connects to data-controller="chart"
export default class extends Controller {
  static targets = [
    "chart",

    "totalRevenueValue",
    "totalRevenueValueFormatted",

    "totalRecoveredRevenueValue",
    "totalRecoveredRevenueValueFormatted",

    "identificationStatusValue",
  ];

  static values = {
    url: String,
    defaultCurrency: String,
  };

  async connect() {
    this.validateInputs();

    const url = new URL(this.urlValue, window.location.href);
    url.search = new URLSearchParams(window.location.search);

    const response = await fetch(url);
    const data = await response.json();

    this.currency = this.setCurrency();

    const chartData = this.buildChartData(data["revenue_timeseries"]);
    this.setMetrics(data);

    createRoot(this.chartTarget).render(
      <Chart currency={this.currency} data={chartData} />
    );
  }

  validateInputs() {
    if (!this.urlValue) {
      throw new Error(`URL for data-controller="chart" must be set`);
    }

    if (!this.defaultCurrencyValue) {
      throw new Error(
        `Default currency value for data-controller="chart" must be set`
      );
    }
  }

  setCurrency() {
    const currencyParam = new URLSearchParams(window.location.search).get(
      "currency"
    );

    if (!!currencyParam) {
      return currencyParam;
    } else {
      return this.defaultCurrencyValue;
    }
  }

  buildChartData(data) {
    const buildData = (timeseries, key) =>
      timeseries.map(({ date, data }) => ({
        x: new Date(date).valueOf(),
        y: data[key],
      }));

    const identified_sessions = buildData(data, "identified_sessions");
    const total_revenue = buildData(data, "total_revenue");
    const recovered_revenue = buildData(data, "recovered_revenue");

    return {
      identified_sessions,
      total_revenue,
      recovered_revenue,
    };
  }

  setMetrics(data) {
    this.totalRevenueValueTarget.textContent = numberToHuman(
      data.total_revenue_value,
      { style: "currency", currency: this.currency, notation: "standard" }
    );
    this.totalRevenueValueFormattedTarget.textContent = numberToHuman(
      data.total_revenue_value,
      { style: "currency", currency: this.currency }
    );

    this.totalRecoveredRevenueValueTarget.textContent = numberToHuman(
      data.total_recovered_revenue_value,
      { style: "currency", currency: this.currency, notation: "standard" }
    );
    this.totalRecoveredRevenueValueFormattedTarget.textContent = numberToHuman(
      data.total_recovered_revenue_value,
      { style: "currency", currency: this.currency }
    );

    createRoot(this.identificationStatusValueTarget).render(
      <IdentificationStatus value={data.identified_sessions_percentage} />
    );
  }
}
