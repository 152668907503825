import { useEffect } from "react";
import { TooltipProvider } from "~/components/tooltip";

import { CustomReport } from "../types";
import { Queries } from "./queries";
import { GoogleSheetLink } from "./googleSheetLink";
import { ReportDetails, ReportHeader } from "./pageComponents";

import { useReportManagement } from "./hooks/useReportManagement";
import {
  useInitialSyncStatusEffect,
  useSubscriptionEffect,
  usePollingEffect,
} from "./hooks/reportEffects";

interface Props {
  report: CustomReport;
}

export const CustomReportDetails = ({ report: initialReport }: Props) => {
  const {
    report,
    combinedReports,
    isAllRefreshable,
    updateReportData,
    setReport,
  } = useReportManagement();

  // Set and watch the initial report
  useEffect(() => {
    setReport(initialReport);
  }, [initialReport, setReport]);

  useInitialSyncStatusEffect(combinedReports);
  useSubscriptionEffect(combinedReports, updateReportData);
  usePollingEffect(combinedReports);

  return (
    <TooltipProvider>
      <div className="flex flex-col max-w-3xl gap-4 p-5 mx-auto border rounded">
        <ReportHeader report={report} isRefreshable={isAllRefreshable} />
        <ReportDetails report={report} />
        <div className="flex flex-row gap-4 pt-5">
          <GoogleSheetLink url={report.google_sheet_url} />
        </div>
      </div>
      <Queries reports={combinedReports} />
    </TooltipProvider>
  );
};
