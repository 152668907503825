import React, { useState, useEffect } from "react";

import { Switch } from "../switch";

interface SwitchProps {
  defaultChecked: boolean;
  url: string;
}

const headers = {
  "Content-Type": "application/json",
  "X-Csrf-Token":
    document.querySelector("[name='csrf-token']")?.getAttribute("content") ??
    "",
};

const updateSwitchState = async (url: string, checked: boolean) => {
  try {
    const response = await fetch(url, {
      method: "PATCH",
      headers,
      body: JSON.stringify({ enabled: !checked }),
    });
    const data = await response.json();
    return data.state;
  } catch (error) {
    console.error("Error:", error);
    return checked; // Return the current checked value in case of an error
  }
};

const ContentToggleSwitch = ({ defaultChecked, url }: SwitchProps) => {
  const [checked, setChecked] = useState(defaultChecked);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  const handleChange = async () => {
    setIsDisabled(true);
    try {
      const newState = await updateSwitchState(url, checked);
      setChecked(newState);
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsDisabled(false);

      // TODO: Remove the following line when the API is implemented:
      setChecked(!checked);
    }
  };

  return (
    <Switch
      onCheckedChange={handleChange}
      checked={checked}
      disabled={isDisabled}
    />
  );
};

export default ContentToggleSwitch;
