import { Product } from "./table";

const headers = {
  "Content-Type": "application/json",
  "X-Csrf-Token":
    document.querySelector("[name='csrf-token']")?.getAttribute("content") ?? ""
};

export const updateProductVisibility = async (
  storeId: string,
  value: Product["visibility"]
) => {
  try {
    const response = await fetch(
      `/stores/${storeId}/automations/update_product_visibility`,
      {
        method: "POST",
        headers,
        body: JSON.stringify({ value })
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Update Product Visibility Error:", error);
    throw error;
  }
};
