import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { cn } from "~/utils/tailwind";

type ErrorsProps = {
  fieldName: string;
  className?: string;
};

export const Errors = ({ fieldName, className }: ErrorsProps) => {
  const {
    formState: { errors },
  } = useFormContext();

  const fieldError = errors[fieldName];

  const errorMessages = useMemo(() => {
    if (!fieldError) return [];

    return [extractMessage(fieldError)];
  }, [fieldError]);

  if (errorMessages.length === 0 || !errorMessages[0]) {
    return null;
  }

  return (
    <p className={cn("text-sm text-red-500 mt-1", className)}>
      {errorMessages[0]}
    </p>
  );
};

const extractMessage = (error: any): string => {
  if (typeof error === "string") return error;
  if (typeof error !== "object" || error === null) return "An error occurred";

  if ("message" in error && typeof error.message === "string")
    return error.message;

  for (const [key, value] of Object.entries(error)) {
    if (typeof value === "string") return value;
    if (typeof value === "object" && value !== null) {
      const nestedMessage = extractMessage(value);
      if (nestedMessage) return nestedMessage;
    }
  }

  return "An error occurred";
};
