import { Controller } from "@hotwired/stimulus";
import { createRoot } from "react-dom/client";

import { TimeZoneProvider } from "~/context/timeZoneContext";

import { CustomReportCard } from "~/components/customReports/list/card";
import { CustomReportDetails } from "~/components/customReports/view/index";
import { CustomReportEdit } from "~/components/customReports/edit/index";
import { CustomReportEditQuery } from "~/components/customReports/edit/query/index";

// Connects to data-controller="custom-report"
export default class extends Controller {
  static targets = ["report"];

  static values = {
    report: Object,
    type: String,
    timeZone: String,
  };

  connect() {
    this.validateInputs();

    if (this.typeValue === "card") {
      createRoot(this.reportTarget).render(
        <CustomReportCard report={this.reportValue} />
      );
    } else if (this.typeValue === "details") {
      createRoot(this.reportTarget).render(
        <TimeZoneProvider initialTimeZone={this.timeZoneValue}>
          <CustomReportDetails report={this.reportValue} />
        </TimeZoneProvider>
      );
    } else if (this.typeValue === "edit-details") {
      createRoot(this.reportTarget).render(
        <CustomReportEdit report={this.reportValue} />
      );
    } else if (this.typeValue === "edit-query") {
      createRoot(this.reportTarget).render(
        <CustomReportEditQuery report={this.reportValue} />
      );
    }
  }

  validateInputs() {
    if (!this.reportTarget) {
      throw new Error(
        `Report target for data-controller="custom-report" must be set`
      );
    }

    if (!this.reportValue) {
      throw new Error(
        `Report value for data-controller="custom-report" must be set`
      );
    }

    if (this.typeValue === "details" && !this.timeZoneValue) {
      throw new Error(
        `Time zone value for details section data-controller="custom-report" must be set`
      );
    }

    if (
      !this.typeValue ||
      (this.typeValue !== "card" &&
        this.typeValue !== "details" &&
        this.typeValue !== "edit-details" &&
        this.typeValue !== "edit-query")
    ) {
      throw new Error(
        `Type value for data-controller="custom-report" must be set to either "card", "details" or "edit-details or edit-query"`
      );
    }
  }
}
