import { Controller } from "@hotwired/stimulus";
import consumer from "../channels/consumer";

export default class extends Controller {
  static values = { importId: String };
  static targets = ["progressBar", "progressPercentage", "fileProgress"];
  connect() {
    console.log("CSV import status controller connected");
    consumer.subscriptions.create(
      { channel: "ImportProgressChannel", import_id: this.importIdValue },
      { received: this._received.bind(this) }
    );
  }

  _received(data) {
    const { progress_percentage, status, current_file_index } = data;
    const progressBarWidth =
      parseFloat(progress_percentage) >= 100 ? 100 : progress_percentage;

    this.progressBarTarget.style.width = progressBarWidth + "%";
    this.progressPercentageTarget.innerText = progress_percentage + "%";

    this.fileProgressTarget.innerText = current_file_index;

    // this is a hack to force rails to render the "completed" view.
    // Otherwise it would just sit on the "running" view forever.
    if (status !== "RUNNING") {
      window.location.reload();
    }
  }
}
