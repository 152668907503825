import {
  numberToHuman,
  numberToPercentage,
} from "~/utils/number-formatters";
import { FrequencyState } from ".";

interface Data {
  totalRevenue: number;
  totalRevenueIncrease: number;
  repeatRevenue: number;
  repeatRevenueIncrease: number;
  highQualityChurnRate: number;
  highQualityChurnRateIncrease: number;
  lowQualityChurnRate: number;
  lowQualityChurnRateIncrease: number;
}

// Demo figures from this Goolge Sheets document:
// https://docs.google.com/spreadsheets/d/1YOfoWRwVYU7uMVafMGxVekupLENeYT7SlGFKfBo0jB0/edit?gid=0#gid=0
export const frequencyDemoData: Data[] = [
  {
    totalRevenue: 4807770,
    totalRevenueIncrease: -2.9,
    repeatRevenue: 1884919.2,
    repeatRevenueIncrease: -6.82,
    highQualityChurnRate: 3.2,
    highQualityChurnRateIncrease: -2.22,
    lowQualityChurnRate: 9.31,
    lowQualityChurnRateIncrease: -11.68,
  },
  {
    totalRevenue: 4869352.5,
    totalRevenueIncrease: -1.66,
    repeatRevenue: 1944038.4,
    repeatRevenueIncrease: -3.9,
    highQualityChurnRate: 2.73,
    highQualityChurnRateIncrease: -16.67,
    lowQualityChurnRate: 8.9,
    lowQualityChurnRateIncrease: -15.57,
  },
  {
    totalRevenue: 4951590,
    totalRevenueIncrease: 0,
    repeatRevenue: 2022986.4,
    repeatRevenueIncrease: 0,
    highQualityChurnRate: 3.27,
    highQualityChurnRateIncrease: 0,
    lowQualityChurnRate: 10.54,
    lowQualityChurnRateIncrease: 0,
  },
  {
    totalRevenue: 5025030,
    totalRevenueIncrease: 1.48,
    repeatRevenue: 2093488.8,
    repeatRevenueIncrease: 3.49,
    highQualityChurnRate: 3.82,
    highQualityChurnRateIncrease: 16.67,
    lowQualityChurnRate: 11.67,
    lowQualityChurnRateIncrease: 10.71,
  },
  {
    totalRevenue: 5069782.5,
    totalRevenueIncrease: 2.39,
    repeatRevenue: 2136451.2,
    repeatRevenueIncrease: 5.61,
    highQualityChurnRate: 5.27,
    highQualityChurnRateIncrease: 61.11,
    lowQualityChurnRate: 14.33,
    lowQualityChurnRateIncrease: 36.01,
  },
];

export const demoData = (step: number) => {
  const data = frequencyDemoData[step];

  return {
    frequency: step,
    revenue: {
      value: data.totalRevenue,
      formatted_value: numberToHuman(data.totalRevenue, {
        style: "currency",
        currency: "USD",
      }),
      difference: data.totalRevenueIncrease,
      difference_formatted: numberToPercentage(data.totalRevenueIncrease, 2),
      difference_state: "normal",
    } as FrequencyState,
    retention: {
      value: data.repeatRevenue,
      formatted_value: numberToHuman(data.repeatRevenue, {
        style: "currency",
        currency: "USD",
      }),
      difference: data.repeatRevenueIncrease,
      difference_formatted: numberToPercentage(data.repeatRevenueIncrease, 2),
      difference_state: "normal",
    } as FrequencyState,
    low_value_churn: {
      value: data.lowQualityChurnRate,
      formatted_value: numberToPercentage(data.lowQualityChurnRate, 2),
      difference: data.lowQualityChurnRateIncrease,
      difference_formatted: numberToPercentage(
        data.lowQualityChurnRateIncrease,
        2
      ),
      difference_state: "inverted",
    } as FrequencyState,
    high_value_churn: {
      value: data.highQualityChurnRate,
      formatted_value: numberToPercentage(data.highQualityChurnRate, 2),
      difference: data.highQualityChurnRateIncrease,
      difference_formatted: numberToPercentage(
        data.highQualityChurnRateIncrease,
        2
      ),
      difference_state: "inverted",
    } as FrequencyState,
  };
};
