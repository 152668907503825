import { Controller } from "stimulus";
import Sortable from "sortablejs";

const headers = {
  "X-Csrf-Token":
    document.querySelector("[name='csrf-token']")?.getAttribute("content") ??
    "",
  "Content-Type": "application/json",
  Accept: "application/json",
};

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this),
    });
  }

  end(event) {
    let id = event.item.dataset.id;
    let url = this.data.get("url").replace(":id", id);
    let data = JSON.stringify({
      content_block: {
        position: event.newIndex + 1,
      },
    });

    fetch(url, {
      method: "PATCH",
      body: data,
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
}
