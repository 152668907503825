import { useEffect } from "react";
import { CustomReport } from "../../types";
import Prism from "prismjs";

export const CodePreview = ({ report }: { report: CustomReport }) => {
  useEffect(() => {
    Prism.highlightAll();
  }, [report.can_view_query, report.query]);

  if (!report.can_view_query) {
    return null;
  }

  return (
    <div>
      <pre className="rounded !my-0 !text-sm">
        <code className="language-sql !max-w-prose !break-words">
          {report.query}
        </code>
      </pre>
    </div>
  );
};
