import { EditQueryFormData } from "../../schemas";
import { CustomReport, ReportQuery } from "../../types";

export const toReportQueries = (reports: CustomReport[]): ReportQuery[] => {
  return reports.map((report) => {
    return toReportQuery(report);
  });
};

export const toReportQuery = (report: CustomReport): ReportQuery => {
  return {
    id: report.id,
    query: report?.query,
    google_sheet_tab_name: report.google_sheet_tab,
    store_id: report.store_id,
  };
};

export const getUpdatedReport = (
  currentReport: CustomReport,
  formData: EditQueryFormData
): CustomReport => {
  if (currentReport.id === formData.report_id) {
    return {
      ...currentReport,
      query: formData.query,
      google_sheet_tab: formData.google_sheet_tab_name,
      status: "RUNNING",
    };
  } else {
    return {
      ...currentReport,
      child_reports: currentReport.child_reports.map((childReport) =>
        childReport.id === formData.report_id
          ? {
              ...childReport,
              query: formData.query,
              google_sheet_tab: formData.google_sheet_tab_name,
              status: "RUNNING",
            }
          : childReport
      ),
    };
  }
};
