import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const REFRESH_INTERVAL = 60_000; // 60,000 ms is 1 minute

const TimeAgo = ({ timestamp }: { timestamp: string }) => {
  const [timeAgo, setTimeAgo] = useState("");

  useEffect(() => {
    const updateTimeAgo = () => {
      setTimeAgo(dayjs(timestamp).fromNow());
    };

    // Update immediately on mount
    updateTimeAgo();

    // Update interval every REFRESH_INTERVAL milliseconds
    const intervalId = setInterval(updateTimeAgo, REFRESH_INTERVAL);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [timestamp]);

  return <span>{timeAgo}</span>;
};

export default TimeAgo;

