import { useState, useEffect } from "react";

import { LuPlus } from "react-icons/lu";

import { CustomReport, ReportQuery } from "../../types";

import { toReportQueries, toReportQuery } from "./utils";

import { GoogleSheetTabSetupInfo } from "../googleSheetSetupInfo";
import EditQuery from "./form";

export const CustomReportEditQuery = ({ report }: { report: CustomReport }) => {
  const emptyQuery: ReportQuery = { store_id: report.store_id };
  const [newQuery, setNewQuery] = useState<ReportQuery | null>(null);
  const [childReportQueries, setChildReportQueries] = useState<ReportQuery[]>(
    toReportQueries(report.child_reports)
  );

  const handleAddQuery = () => {
    setNewQuery(emptyQuery);
  };

  const handleDelete = (reportQuery: ReportQuery) => {
    setChildReportQueries(
      childReportQueries.filter(
        (reportQueryItem) => reportQueryItem.id !== reportQuery.id
      )
    );
  };

  const handleCancel = () => {
    setNewQuery(null);
  };

  const handleNewSubmitted = (reportQuery: ReportQuery) => {
    setChildReportQueries([...childReportQueries, reportQuery]);
    setNewQuery(null);
  };

  useEffect(() => {
    const hash = window.location.hash.substring(1); // Get the hash and remove the '#'
    const element = document.getElementById(hash);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div className="relative flex flex-col gap-4">
      <GoogleSheetTabSetupInfo />

      <div className="flex flex-col gap-10">
        {/* Parent custom report query */}
        <div id={report.id}>
          <EditQuery
            reportQuery={toReportQuery(report)}
            parentReport={null}
            onCancel={() => {}}
            onSubmit={() => {}}
            onDelete={() => {}}
          />
        </div>

        {/* Child custom report queries */}
        {childReportQueries.map((reportQuery) => (
          <div id={reportQuery.id} key={reportQuery.id}>
            <EditQuery
              reportQuery={reportQuery}
              parentReport={report}
              onCancel={() => {}}
              onSubmit={() => {}}
              onDelete={handleDelete}
            />
          </div>
        ))}

        {/* New query */}
        {newQuery ? (
          <EditQuery
            reportQuery={newQuery}
            parentReport={report}
            onCancel={handleCancel}
            onSubmit={handleNewSubmitted}
            onDelete={() => {}}
          />
        ) : (
          <button
            className="flex items-center px-5 btn w-fit"
            onClick={handleAddQuery}
          >
            <LuPlus className="mr-2" size={20} />
            Add another query
          </button>
        )}
      </div>
    </div>
  );
};
