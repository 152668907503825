import { ReactNode, createContext, useState } from "react";

type TimeZoneContextType = {
  timeZone: string;
  setTimeZone: (timeZone: string) => void;
};

export const TimeZoneContext = createContext<TimeZoneContextType>({
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  setTimeZone: () => {},
});

type TimeZoneProviderProps = {
  children: ReactNode;
  initialTimeZone?: string;
};

export const TimeZoneProvider = ({
  children,
  initialTimeZone,
}: TimeZoneProviderProps) => {
  const [timeZone, setTimeZone] = useState(
    initialTimeZone || Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const value = { timeZone, setTimeZone };

  return (
    <TimeZoneContext.Provider value={value}>
      {children}
    </TimeZoneContext.Provider>
  );
};
